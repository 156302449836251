import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import QuestionAnswer from '@mui/icons-material/QuestionAnswer';
import {Badge, Box, Typography} from '@mui/material';
import Divider from '@mui/material/Divider';
import {useTranslation} from 'locales/useTranslation';
import React, {FC} from 'react';

import {ChatBox} from '../ui';
import {Room} from './RoomList.styled';
import {RoomListProps} from './RoomList.types';

const RoomList: FC<RoomListProps> = ({rooms, setRoom, notifications}) => {
  const t = useTranslation();

  return (
    <>
      <ChatBox>
        <Box component="div" sx={{display: 'grid', gap: '5px'}}>
          <Typography
            variant="h6"
            sx={{fontSize: '1rem', fontWeight: 'bold', p: '5px 20px'}}
          >
            {t.chat.chooseChat}:
          </Typography>
          <Divider />
          {rooms.map((room, index) => {
            const hasNotification = notifications.filter(
              el => el === room.id,
            ).length;

            return (
              <React.Fragment key={`${room.id}-${index}`}>
                <Room onClick={() => setRoom(room.id)}>
                  <Badge badgeContent={hasNotification} color="secondary">
                    <QuestionAnswer color="primary" fontSize="large" />
                  </Badge>

                  <Typography variant="subtitle1" sx={{flexBasis: '50%'}}>
                    {room.name}
                  </Typography>

                  <KeyboardArrowRight color="primary" fontSize="large" />
                </Room>
                <Divider />
              </React.Fragment>
            );
          })}
        </Box>
      </ChatBox>
    </>
  );
};

export default RoomList;
