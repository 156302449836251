import PhoneIcon from '@mui/icons-material/Phone';
import IconButton from '@mui/material/IconButton';
import useDraggable from 'hooks/useDraggable';
import useSip from 'hooks/useSip';
import {useTranslation} from 'locales/useTranslation';
import {DirectionEnum} from 'models/Direction';
import {FC, useReducer} from 'react';
import Draggable from 'react-draggable';

import {CallActions} from './CallActions';
import {CallInfo} from './CallInfo';
import {ExternalNumbersSelect} from './external-numbers/ExternalNumbersSelect';
import {
  ArrowIcon,
  CallContainer,
  CallToDispatcherContainer,
  DraggableContainer,
  OutCallConnecting,
  StyledBox,
  WhiteIconButton,
} from './styled';

export const DraggableCallAction: FC = () => {
  const {position, setPosition} = useDraggable();

  const t = useTranslation();

  const [toggleSize, setToggleSize] = useReducer(
    (state: boolean) => !state,
    false,
  );

  const [
    {
      callStartedAt,
      remoteIdentity,
      callDirection,
      isOnHold,
      isInCall,
      isClientConnected,
    },
    {hold, unhold, terminate},
  ] = useSip();

  const outCallStarted = isClientConnected ? callStartedAt : null;

  return (
    <>
      {isInCall ? (
        <Draggable
          bounds="parent"
          defaultPosition={position}
          onStop={setPosition}
          position={position}
        >
          <DraggableContainer>
            <CallContainer toggleSize={toggleSize}>
              <StyledBox toggleSize={toggleSize}>
                <CallInfo
                  callStartedAt={
                    callDirection === DirectionEnum.OUT
                      ? outCallStarted
                      : callStartedAt
                  }
                  remoteIdentity={remoteIdentity}
                  toggleSize={toggleSize}
                />
                {callDirection === DirectionEnum.OUT && !isClientConnected ? (
                  <OutCallConnecting>{t.action.connecting}</OutCallConnecting>
                ) : null}
                <CallActions
                  holdCall={hold}
                  isOnHold={isOnHold}
                  unHoldCall={unhold}
                  endCall={terminate}
                  callDirection={callDirection}
                />
              </StyledBox>
              <IconButton size="small" onClick={() => setToggleSize()}>
                <ArrowIcon down={toggleSize} />
              </IconButton>
            </CallContainer>
            {toggleSize ? (
              <CallToDispatcherContainer>
                <ExternalNumbersSelect />
                <WhiteIconButton size="small">
                  <PhoneIcon color="success" />
                </WhiteIconButton>
              </CallToDispatcherContainer>
            ) : null}
          </DraggableContainer>
        </Draggable>
      ) : null}
    </>
  );
};
