import {useTranslation} from 'locales/useTranslation';

export function useApiErrors() {
  const t = useTranslation();
  const API_ERRORS = {...t.apiErrors};

  type ApiErrorKeys = keyof typeof API_ERRORS;

  return [
    (error: string, customDefaultErrorMessage?: string) =>
      API_ERRORS[error as ApiErrorKeys] ??
      customDefaultErrorMessage ??
      API_ERRORS.default,
  ];
}
