import {createTheme} from '@mui/material';

const classicTheme = createTheme({
  palette: {
    secondary: {
      main: '#F50057',
      light: '#DFE1DF',
    },
  },
  components: {
    MuiFormControl: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
        autoComplete: 'off',
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ownerState}) => ({
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'inherit' && {
              color: '#000000',
            }),
        }),
      },
    },
    MuiFab: {
      styleOverrides: {
        root: ({ownerState}) => ({
          ...(ownerState.color === 'inherit' && {
            ':hover': {
              backgroundColor: '#d5d5d5',
            },
          }),
        }),
      },
    },
  },
});

export default classicTheme;
