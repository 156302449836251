/* eslint-disable @typescript-eslint/no-empty-function */
import {CallDto, SnoopQueryDto} from '@horn1/api';
import {useEffect, useMemo, useState} from 'react';

import type {
  Operator,
  SipService,
  SipServiceState,
} from '../services/SipService';
import useService from './useService';

const defaultSipState: SipServiceState = {
  isStarting: false,
  isStarted: false,
  isIoConnected: false,
  isSipConnecting: false,
  isSipConnected: false,
  isSipRegistered: false,
  isRinging: false,
  isInCall: false,
  stream: null,
  remoteStream: null,
  remoteIdentity: null,
  isOnHold: false,
  isMuted: false,
  isPostprocessing: false,
  finishPostProcessingAt: null,
  callStartedAt: null,
  isRejectCall: false,
  finishRejectCallAt: null,
  canMakeOutgoingCall: false,
  callDirection: null,
  isClientConnected: false,
  isNumberInBlacklist: false,
  client: null,
  isSnooping: false,
  callPriority: false,
};

export interface SipMethods {
  disconnectUser(): void;

  connect(): Promise<void>;

  start(): void;

  stop(): void;

  hold(): void;

  unhold(): void;

  terminate(): void;

  answer(): void;

  listen(snoopQueryDto: SnoopQueryDto): void;

  drop(): void;

  mute(): void;

  unmute(): void;

  reject(): void;

  stopPostprocessing(user?: Operator): void;

  addListener(event: string, func: (call: CallDto) => void): void;

  removeListener(event: string, func: (call: CallDto) => void): void;

  errors: typeof SipService.errors;

  startByUser(user: Operator): void;

  stopByUser(user: Operator): void;

  call(number: string, applicationId: number): void;
}

export default function useSip(): [SipServiceState, SipMethods] {
  const sipService: SipService = useService<SipService>(
    'sip',
    () => import('../services/SipService'),
  );

  const [sipState, setSipState] = useState<SipServiceState>(() => {
    if (sipService) {
      return sipService.getState();
    }

    return defaultSipState;
  });

  useEffect(() => {
    if (!sipService) {
      return;
    }

    const stateHandler = (newState: SipServiceState) => {
      setSipState(newState);
    };

    sipService.on('state', stateHandler);

    return () => {
      sipService.removeListener('state', stateHandler);
    };
  }, [sipService]);

  const methods = useMemo(() => {
    if (!sipService) {
      return {
        connect() {
          return Promise.resolve();
        },
        disconnectUser() {},
        start() {},
        stop() {},
        startByUser() {},
        stopByUser() {},
        answer() {},
        listen() {},
        drop() {},
        terminate() {},
        reject() {},
        hold() {},
        unhold() {},
        mute() {},
        unmute() {},
        stopPostprocessing() {},
        addListener() {},
        removeListener() {},
        call() {},
        errors: {} as unknown as typeof SipService.errors,
      };
    }

    return {
      connect: sipService.connectIo.bind(sipService),
      disconnectUser: sipService.disconnectUser.bind(sipService),
      start: sipService.start.bind(sipService),
      stop: sipService.stop.bind(sipService),
      startByUser: sipService.startByUser.bind(sipService),
      stopByUser: sipService.stopByUser.bind(sipService),
      answer: sipService.answer.bind(sipService),
      listen: sipService.listen.bind(sipService),
      drop: sipService.drop.bind(sipService),
      terminate: sipService.terminate.bind(sipService),
      reject: sipService.rejectCall.bind(sipService),
      hold: sipService.hold.bind(sipService),
      unhold: sipService.unhold.bind(sipService),
      mute: sipService.mute.bind(sipService),
      unmute: sipService.unmute.bind(sipService),
      stopPostprocessing: sipService.stopPostprocessing.bind(sipService),
      addListener: sipService.addListener.bind(sipService),
      removeListener: sipService.removeListener.bind(sipService),
      call: sipService.call.bind(sipService),
      errors: (sipService.constructor as typeof SipService).errors,
    };
  }, [sipService]);

  return [sipState, methods];
}
