import React from 'react';

const SimpleProgress: React.FC = () => {
  return (
    <div className="progress-cont">
      <div className="progress-root">
        <svg viewBox="22 22 44 44">
          <circle cx={44} cy={44} r={20.2} fill="none" strokeWidth={3.6} />
        </svg>
      </div>
    </div>
  );
};

export default SimpleProgress;
