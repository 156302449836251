import {CallDto} from '@horn1/api';
import PushPinIcon from '@mui/icons-material/PushPin';
import {Box, IconButton} from '@mui/material';
import Button from '@mui/material/Button';
import CountDown from 'components/CountDown';
import useDraggable from 'hooks/useDraggable';
import useOptions from 'hooks/useOptions';
import useSIP from 'hooks/useSip';
import {useSnackBar} from 'hooks/useSnackBar';
import {useTranslation} from 'locales/useTranslation';
import {DirectionEnum} from 'models/Direction';
import {FC, useEffect, useState} from 'react';
import {useHistory, withRouter} from 'react-router-dom';

import AudioStream from '../AudioStream';
import {ErrorSnackbar, IncomingCallDialog} from './components';
import {PendingApplicationsDialog} from './components/pending-applications/PendingApplicationsDialog';

const SipStatus: FC = () => {
  const [
    {
      isStarting,
      isStarted,
      isSipConnected,
      isIoConnected,
      isRinging,
      isOnHold,
      isPostprocessing,
      remoteStream,
      finishPostProcessingAt,
      remoteIdentity,
      isInCall,
      isNumberInBlacklist,
      client,
      callPriority,
    },
    {
      start,
      stop,
      reject,
      answer,
      stopPostprocessing,
      addListener,
      removeListener,
      errors,
    },
  ] = useSIP();

  const [options] = useOptions();
  const {showPendingApplications} = options || {};

  const {setToDefault, showPin} = useDraggable();

  const [call, setCall] = useState<CallDto | null>(null);

  const {showSnackBar} = useSnackBar();
  const t = useTranslation();

  const history = useHistory();

  useEffect(() => {
    if (!isStarting && !isStarted) {
      return;
    }

    function handleBeforeUnload(event: BeforeUnloadEvent) {
      event.preventDefault();

      if (localStorage.getItem('hasCookie')) {
        event.returnValue = '';
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [isStarting, isStarted]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const handleNewCall = (call: CallDto) => {
      setCall(call);

      if (call.direction === DirectionEnum.IN && !showPendingApplications) {
        history.push(`/calls/${call.id}`);
      }
    };

    const handleError = (error: unknown) => {
      if (error instanceof errors.SipAlreadInQueueError) {
        showSnackBar(t.message.sip.alreadyInQueue, {variant: 'error'});
      }

      if (error instanceof errors.NoHavePermissionError) {
        showSnackBar(t.message.sip.canNotConnection, {variant: 'error'});
      }
    };

    addListener('currentCall', handleNewCall);
    addListener('error', handleError);

    return () => {
      removeListener('currentCall', handleNewCall);
      removeListener('error', handleError);
    };
  }, [
    addListener,
    errors.SipAlreadInQueueError,
    history,
    removeListener,
    showPendingApplications,
  ]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const openPendingApplicationsDialog =
    isInCall && showPendingApplications && call;

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        '& > *': {
          mr: 0.25,
          ml: 0.25,
        },
      }}
    >
      {isInCall ? (
        <>
          {showPin ? (
            <IconButton onClick={setToDefault} sx={{color: '#fff'}}>
              <PushPinIcon fontSize="medium" color="inherit" />
            </IconButton>
          ) : null}
        </>
      ) : (
        <>
          {isPostprocessing ? (
            <Box
              sx={{
                display: 'flex',
                gap: '15px',
                alignItems: 'center',
              }}
            >
              {finishPostProcessingAt && (
                <CountDown finishAt={finishPostProcessingAt} className="mr-1" />
              )}

              <Button
                variant="contained"
                color="secondary"
                onClick={() => stopPostprocessing()}
              >
                {t.action.spotPostPrecessing}
              </Button>
            </Box>
          ) : (
            <>
              <Button
                variant="contained"
                color={isStarted ? 'secondary' : 'inherit'}
                onClick={isStarted ? stop : start}
                disabled={isStarting}
                sx={{width: '130px'}}
              >
                {isStarted ? t.action.disconnect : t.action.connect}
              </Button>
            </>
          )}
        </>
      )}

      <ErrorSnackbar
        open={isStarting}
        severity="info"
        message={t.message.sip.connection}
      />

      <ErrorSnackbar
        open={isStarted && !isSipConnected && isIoConnected}
        message={t.message.sip.lostAsterisk}
      />

      <ErrorSnackbar
        open={isStarted && isSipConnected && !isIoConnected}
        message={t.message.sip.lostQueue}
      />

      <ErrorSnackbar
        open={isStarted && !isSipConnected && !isIoConnected}
        message={t.message.sip.lostServer}
      />

      {isRinging && (
        <IncomingCallDialog
          reject={reject}
          answer={answer}
          remoteIdentity={remoteIdentity}
          clientName={client?.name}
          clientAddress={client?.addresses}
          isNumberInBlackList={isNumberInBlacklist}
          callPriority={callPriority}
        />
      )}

      {openPendingApplicationsDialog && (
        <PendingApplicationsDialog
          remoteIdentity={remoteIdentity}
          call={call}
        />
      )}

      {remoteStream && !isOnHold && <AudioStream stream={remoteStream} />}
    </Box>
  );
};

export default withRouter(SipStatus);
