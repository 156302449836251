import React from 'react';
import {FormattedDate, FormattedTime} from 'react-intl';

const FormattedFullDate: React.FC<{value?: Date | string | null}> = ({
  value,
}) => {
  if (!value) {
    return null;
  }

  if (!(value instanceof Date)) {
    value = new Date(value);
  }

  return (
    <React.Fragment>
      <FormattedDate value={value} />
      &nbsp;
      <FormattedTime value={value} />
    </React.Fragment>
  );
};

export default React.memo(FormattedFullDate);
