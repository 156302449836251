import {Tooltip} from '@mui/material';
import Zoom from '@mui/material/Zoom';

import {TooltipTitle} from './ShowTooltip.styled';
import {TooltipValue} from './ShowTooltip.types';

export const ShowTooltip = (value: TooltipValue) => {
  let tooltipValue;

  if (!value) {
    tooltipValue = '-';
  } else if (Array.isArray(value)) {
    tooltipValue = value.join('\n');
  } else {
    tooltipValue = value;
  }

  return (
    <Tooltip
      arrow
      placement="left"
      TransitionComponent={Zoom}
      title={<TooltipTitle>{tooltipValue}</TooltipTitle>}
    >
      <span>{tooltipValue}</span>
    </Tooltip>
  );
};
