import ClearIcon from '@mui/icons-material/Clear';
import {styled} from '@mui/material';
import {useSnackbar} from 'notistack';

export const MuiClearIcon = styled(ClearIcon)(() => ({
  cursor: 'pointer',
  margin: '0 5px 0 0',
  color: '#fff',
  borderRadius: '50%',
  transition: 'background-color 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: 'rgba(213, 213, 213, 0.5)',
  },
}));
export const CloseSnackbarAction = (snackbarId: number) => {
  const {closeSnackbar} = useSnackbar();

  return (
    <MuiClearIcon onClick={() => closeSnackbar(snackbarId)} fontSize="medium" />
  );
};
