import Typography from '@mui/material/Typography';
import {ApplicationTaskProps} from 'components/sip-status/SipStatus.models';
import {useTranslation} from 'locales/useTranslation';
import React, {FC} from 'react';
import {useHistory} from 'react-router-dom';

import FormattedFullDate from '../../../FormattedFullDate';
import {TaskContainer, TaskContent} from './PendingApplicationsDialog.styled';

const ApplicationTask: FC<ApplicationTaskProps> = ({
  application,
  closeDialog,
}) => {
  const t = useTranslation();

  const history = useHistory();

  const openApplicatoinHandler = () => {
    closeDialog();
    history.push(`/applications/${application.id}`);
  };

  return (
    <TaskContainer onClick={openApplicatoinHandler}>
      <Typography gutterBottom component="p" color="primary">
        {t.application.titles(application.id)}
      </Typography>
      <TaskContent>
        <Typography variant="body2" color="textSecondary" component="p">
          <FormattedFullDate value={application.createdAt} />
        </Typography>

        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          sx={{maxWidth: '200px'}}
        >
          {application.topic?.name}
        </Typography>
      </TaskContent>
    </TaskContainer>
  );
};

export default ApplicationTask;
