import {createContext} from 'react';

export interface Options {
  controlMode: boolean;
  postProcessing: number;
  timeOfRejectStatus: number;
  showPendingApplications: boolean;
  highlightBlacklistClient: boolean;
  workShiftHours: number;
  indicatorsSubmissionDates: {
    start: string;
    end: string;
  };
  callPrioritySetting: {
    use: boolean;
    text: string;
  };
  referenceIndicator: number;
}

const valueNoop = (value: Options) => {
  return;
};

type OptionsContextProps = [Options | null, (value: Options) => void];

const OptionsContext = createContext<OptionsContextProps>([null, valueNoop]);

export default OptionsContext;
