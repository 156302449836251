const padToTwoDigits = (padNumber: number) => {
  return String(padNumber).padStart(2, '0');
};

const millisecondsToTime = (ms: number) => {
  const seconds = Math.floor((ms / 1000) % 60);
  const minutes = Math.floor((ms / (1000 * 60)) % 60);
  const hours = Math.floor(ms / (1000 * 60 * 60));

  return hours
    ? `${hours}:${padToTwoDigits(minutes)}:${padToTwoDigits(seconds)}`
    : `${padToTwoDigits(minutes)}:${padToTwoDigits(seconds)}`;
};

export const getState = (
  dateFrom: number,
  dateTo: number,
): {ms: number; time: string} => {
  const ms = dateTo - dateFrom;

  if (ms < 0) {
    return {
      ms: 0,
      time: '00:00',
    };
  }

  return {
    ms,
    time: millisecondsToTime(ms),
  };
};
