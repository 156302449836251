import Typography from '@mui/material/Typography';
import {CallTaskProps} from 'components/sip-status/SipStatus.models';
import {useTranslation} from 'locales/useTranslation';
import React, {FC} from 'react';
import {useHistory} from 'react-router-dom';

import FormattedFullDate from '../../../FormattedFullDate';
import {TaskContainer, TaskContent} from './PendingApplicationsDialog.styled';

const CallTask: FC<CallTaskProps> = ({call, closeDialog}) => {
  const t = useTranslation();
  const history = useHistory();

  const openCallHandler = () => {
    closeDialog();
    history.push(`/calls/${call.id}`);
  };

  return (
    <TaskContainer onClick={openCallHandler}>
      <Typography gutterBottom component="p" color="primary">
        {t.tasks.incomingCall(call.id)}
      </Typography>
      <TaskContent>
        <Typography variant="body2" color="textSecondary" component="p">
          <FormattedFullDate value={call.createdAt} />
        </Typography>

        {call.applications.map(application => (
          <Typography
            key={application.id}
            variant="body2"
            color="textSecondary"
            component="p"
            sx={{maxWidth: '200px'}}
          >
            {application.topic?.name}
          </Typography>
        ))}
      </TaskContent>
    </TaskContainer>
  );
};

export default CallTask;
