import {styled} from '@mui/material';
import TextField from '@mui/material/TextField';
import Form from 'components/Form';

export const Root = styled(Form)({
  zIndex: 2,
  display: 'flex',
  justifyContent: 'space-between',
  width: 'auto',
  position: 'relative',
  height: '100%',

  '& > *': {
    fontSize: '12px',
  },
});

export const MessageInput = styled(TextField)({
  width: '100%',
  height: '100%',
  backgroundColor: '#FFFFFF',

  '& .MuiInputBase-input': {
    padding: '5px 10px 5px ',
    '&::-webkit-scrollbar': {
      position: 'absolute !important',
      width: '0px !important',
    },
  },
});
