import 'react-virtualized/styles.css';

import AppProviders from 'components/providers/AppProviders';
import SimpleProgress from 'components/SimpleProgress';
import React, {Suspense} from 'react';
import AppRoutes from 'routes/AppRoutes';

import {useLocale} from './hooks/use-locale/useLocale';

function App() {
  const [locale, setLocale] = useLocale();

  return (
    <Suspense fallback={<SimpleProgress />}>
      <AppProviders locale={locale} handleLocaleChange={setLocale}>
        <AppRoutes />
      </AppProviders>
    </Suspense>
  );
}

export default App;
