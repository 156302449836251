import {Permissions} from '@horn1/api';

export const OTHER_TAB_PERMISSIONS = [Permissions.Testing];

export const REPORTS_TAB_PERMISSIONS = [
  Permissions.ViewHistoryReport,
  Permissions.ReadNercTopicsReport,
  Permissions.ReadConsumerEvaluationReports,
  Permissions.RatingReport,
  Permissions.ReadOperatorWorkReports,
  Permissions.ReadSlaReports,
  Permissions.ReadQualitySupplyServices,
  Permissions.ReadExtendedReports,
  Permissions.ReadToeOperatorReports,
  Permissions.AstorReport,
  Permissions.ReadNercSlaReport,
  Permissions.ViewHistoryAutoReport,
];

export const DISPATCHER_MODULE_TAB_PERMISSIONS = [
  Permissions.ReadDispatcherModuleTable,
];

export const HANDBOOKS_TAB_PERMISSIONS = [
  Permissions.ReadClients,
  Permissions.ReadExternalPhoneNumbers,
];

export const SETTINGS_TAB_PERMISSIONS = [
  Permissions.ReadGeneralSetting,
  Permissions.ReadUsers,
  Permissions.ReadRoles,
  Permissions.ReadSounds,
  Permissions.ReadTopics,
  Permissions.ReadRoutes,
  Permissions.ReadRules,
  Permissions.ReadSchedule,
  Permissions.ReadTrunks,
  Permissions.ReadBlacklist,
  Permissions.ReadMailSenderConfigs,
];

export const DEMO_SETTINGS_PERMISSIONS = [Permissions.ReadsettingsInterface];

export const INTERNAL_TOOLS_PERMISSIONS = [
  Permissions.ReadInternalToolsTimeTracking,
  Permissions.ReadInternalToolsDataBase,
];

export const EXTERNAL_TOOLS_PERMISSIONS = [
  Permissions.ReadExternalToolsAI,
  Permissions.ReadExternalToolsAdveristingOffices,
  Permissions.ReadExternalToolsDB,
  Permissions.ReadExternalToolsNPSAgents,
  Permissions.ReadExternalToolsSuperAgents,
  Permissions.ReadExternalToolsVirtualAgents,
];

export const OUTPUT_CHANELS_PERMISSIONS = [
  Permissions.ReadOutputChanelsCallback,
  Permissions.ReadOutputChanelsEmail,
  Permissions.ReadOutputChanelsMessengers,
  Permissions.ReadOutputChanelsSms,
];

export const INPUT_CHANELS_PERMISSIONS = [
  Permissions.ReadInputChanelsCRM,
  Permissions.ReadInputChanelsCRMSiteBilder,
  Permissions.ReadInputChanelsEmail,
  Permissions.ReadInputChanelsForms,
  Permissions.ReadInputChanelsMarketplace,
  Permissions.ReadInputChanelsMessengers,
  Permissions.ReadInputChanelsSocialNetworcs,
  Permissions.ReadInputChanelsVideoMessengers,
  Permissions.ReadInputChanelsWebChats,
];

export const CRM_PERMISSIONS = [Permissions.ReadCanban, Permissions.ReadList];
