import {PermissionValueType} from '@horn1/api';
import * as Sentry from '@sentry/browser';
import api from 'api';
import {Login, UserDto} from 'api/rest/authorization';
import UserContext, {CurrentUser} from 'contexts/UserContext';
import useSip from 'hooks/useSip';
import React, {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ApiError} from 'utils/fetcher';

const UserProvider: React.FC = ({children}) => {
  const [user, setUser] = useState<CurrentUser | null | undefined>(undefined);
  const history = useHistory();
  const [, {connect}] = useSip();

  useEffect(() => {
    if (!user) {
      return;
    }

    connect();
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const handlerUserChange = useCallback((user: UserDto) => {
    setUser({
      ...user,
      permissions: new Set(user.permissions),
    });
    Sentry.setUser(user);
  }, []);

  const [methods] = useState({
    async login(credentials: Login) {
      await api.authorization.login(credentials);
      await methods.sync();
    },
    async sync() {
      try {
        const user = await api.authorization.sync();

        setUser({
          ...user,
          permissions: new Set(user.permissions),
        });
        Sentry.setUser(user);
      } catch {
        return setUser(null);
      }
    },
    async logout() {
      try {
        localStorage.removeItem('hasCookie');
        await api.authorization.logout();
      } catch (e) {
        if (e instanceof ApiError) {
          if (e.statusCode === 401) {
            history.push('/login');
          }
        }
      } finally {
        setUser(null);
      }
    },

    setUser: handlerUserChange,
  });

  useEffect(() => {
    methods.sync();
  }, [methods]);

  const hasPermission = useCallback(
    (permission: PermissionValueType | PermissionValueType[]) => {
      if (Array.isArray(permission)) {
        return user
          ? permission.some(permission => user.permissions.has(permission))
          : false;
      }

      return user ? user.permissions.has(permission) : false;
    },
    [user],
  );

  return (
    <UserContext.Provider value={[user, methods, hasPermission]}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
