import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import ServiceContext, {
  IServiceContext,
  IServiceSubscription,
} from '../../contexts/ServiceContext';

interface ServiceInstance {
  unload?(): Promise<void>;
}

interface ServiceConstructor {
  new (): ServiceInstance;
}

type LoadFunc = () => Promise<{default: ServiceConstructor}>;

enum ServiceState {
  Loading = 0,
  Loaded,
  Unloading,
}

interface Service {
  name: string;
  state: ServiceState;
  refCount: number;
  loadFunc: LoadFunc;
  instance: ServiceInstance | null;
}

interface ServiceProviderProps {
  children: React.ReactNode;
}

const ServiceProvider: React.FC<ServiceProviderProps> = ({children}) => {
  const unloadFuncs = useRef<{[key: string]: () => void}>({});
  const [services, setServices] = useState<{
    [key: string]: Service | undefined;
  }>({});

  const getService = useCallback(
    (name: string) => {
      const service = services[name];

      return service?.instance || null;
    },
    [services],
  );

  const unloadService = useCallback((name: string) => {
    setServices(currentServices => {
      const currentService: Service | undefined = currentServices[name];

      if (!currentService) {
        return currentServices;
      }

      const newService: Service | undefined = {
        ...currentService,
        refCount: currentService.refCount - 1,
      };

      return {
        ...currentServices,
        [name]: newService,
      };
    });
  }, []);

  const loadService = useCallback(
    (name: string, loadFunc: LoadFunc): IServiceSubscription => {
      setServices(currentServices => {
        const currentService: Service = currentServices[name] || {
          name,
          state: ServiceState.Loading,
          refCount: 0,
          loadFunc,
          instance: null,
        };

        if (
          currentService.refCount === 0 &&
          currentService.state !== ServiceState.Loaded
        ) {
          loadFunc().then(({default: Constructor}) => {
            setServices(currentServices => {
              const currentService = currentServices[name];

              if (!currentService) {
                return currentServices;
              }

              try {
                const instance = new Constructor();

                return {
                  ...currentServices,
                  [name]: {
                    ...currentService,
                    state: ServiceState.Loaded,
                    instance,
                  },
                };
              } catch (error) {
                console.error(error);

                return currentServices;
              }
            });
          });
        }

        return {
          ...currentServices,
          [name]: {
            ...currentService,
            refCount: currentService.refCount + 1,
          },
        };
      });

      if (!unloadFuncs.current[name]) {
        unloadFuncs.current[name] = () => unloadService(name);
      }

      const unsubscribe = unloadFuncs.current[name] as () => void;

      return {
        unsubscribe,
      };
    },
    [unloadService],
  );

  const value = useMemo<IServiceContext>(
    () => ({
      subscribe(name: string, loadFunc: LoadFunc) {
        return loadService(name, loadFunc);
      },
      get(name: string) {
        return getService(name);
      },
    }),
    [loadService, getService],
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      Object.values(services).forEach(service => {
        if (service && !service.refCount) {
          service.instance?.unload?.();
        }
      });
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [services]);

  return (
    <ServiceContext.Provider value={value}>{children}</ServiceContext.Provider>
  );
};

export default ServiceProvider;
