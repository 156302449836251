import {createContext} from 'react';

import {defaultLocale, LocaleType} from '../locales/translations';
import noop from '../utils/noop';

interface LocaleContextValue {
  locale: LocaleType;
  setLocale: (locale: LocaleType) => void;
}

export const LocaleContext = createContext<LocaleContextValue>({
  locale: defaultLocale,
  setLocale: noop,
});
