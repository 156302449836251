import {useCallback, useState} from 'react';

import {defaultLocale, LocaleType} from '../../locales/translations';
import {UseLocaleType} from './useLocale.types';

export function useLocale(): UseLocaleType {
  const storedLocale = localStorage.getItem('locale') as LocaleType | null;

  const [locale, setLocale] = useState<LocaleType>(
    storedLocale ?? defaultLocale,
  );

  const getLocaleFromLocalStorage = (): LocaleType => {
    const storedLocale = localStorage.getItem('locale') as LocaleType | null;

    return storedLocale ?? locale;
  };

  const handleChangeLocale = useCallback((locale: LocaleType): void => {
    setLocale(locale);
    localStorage.setItem('locale', locale);
  }, []);

  return [locale, handleChangeLocale, getLocaleFromLocalStorage];
}
