/* eslint-disable react-hooks/exhaustive-deps */
import {useContext, useEffect} from 'react';

import ServiceContext from '../contexts/ServiceContext';

export default function useService<T>(
  type: string,
  loadFunc: () => Promise<unknown>,
) {
  const loader = useContext(ServiceContext);

  useEffect(() => {
    const subscription = loader.subscribe(type, loadFunc);

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return loader.get(type) as T;
}
