import {ChatDto, CreateNewChatDto, UserDto} from '@horn1/api';
import {createContext, MutableRefObject} from 'react';

interface ChatsContextProps {
  isRoomsLoaded: boolean;
  isRoomOpen: boolean;
  isFormOpen: boolean;
  isModalOpen: boolean;
  rooms: ChatDto[] | undefined;
  socketIo: MutableRefObject<SocketIOClient.Socket | null>;
  activeRoom: ChatDto | null;
  notifications: number[];
  chatRoomUsers?: UserDto[];
  methods: {
    getMessageHistory: (take: number, skip: number) => void;
    setRoom: (id: number | null) => void;
    joinRoom: () => void;
    createRoom: (body: CreateNewChatDto) => void;
    deleteRoom: (roomId: number) => void;
    leaveRoom: () => void;
    isRoomOpenHandler: (value: boolean) => void;
    openFormToggle: (value: boolean) => void;
    modalToggle: () => void;
  };
}

const ChatsContext = createContext<ChatsContextProps>(
  null as unknown as ChatsContextProps,
);

export default ChatsContext;
