import {styled} from '@mui/material';
import Button from '@mui/material/Button';
import Form from 'components/Form';

export const FormWrapper = styled('div')({
  width: '100%',
  height: '100%',
  borderRadius: '10px',
  backgroundColor: '#fff',
});

export const FormHeader = styled('div')(({theme}) => ({
  padding: '10px 10px',
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  '& > span': {
    color: '#ffffff86',
    paddingRight: 15,
    fontSize: '1rem',
    fontWeight: 'bold',
  },
}));

export const CreateChatForm = styled(Form)({
  padding: '25px 20px',
  display: 'grid',
  gap: 25,
});

export const InputWrapper = styled('div')({
  overflowY: 'auto',
  paddingTop: '10px',
  maxHeight: '30vh',
});

export const CreateChatBtn = styled(Button)({
  padding: '15px',
  fontWeight: 'bold',
  fontSize: '1rem',
  textTransform: 'none',
  borderRadius: '15px',
});
