import React, {FC} from 'react';

import {ConnectedUsersListProps} from './ConnectedUsersList.types';

export const ConnectedUsersList: FC<ConnectedUsersListProps> = ({
  connectedUsersId,
  allUsers,
}) => {
  return (
    <ul>
      {connectedUsersId.map((id, index) => {
        return (
          <li key={`${id}-${index}`}>
            {allUsers.find(user => user.id === id)?.fullName}
          </li>
        );
      })}
    </ul>
  );
};
