import PersonIcon from '@mui/icons-material/Person';
import TimerIcon from '@mui/icons-material/Timer';
import Timer from 'components/timer/Timer';
import useSIP from 'hooks/useSip';
import {FC} from 'react';

import {CallInfoProps} from '../SipStatus.models';
import {CallInfoContainer, CallInfoLabel, ClientIdentity} from './styled';

export const CallInfo: FC<CallInfoProps> = ({
  remoteIdentity,
  callStartedAt,
  toggleSize,
}) => {
  const [{client: sipClient}] = useSIP();

  return (
    <CallInfoContainer toggleSize={toggleSize}>
      <CallInfoLabel>
        <PersonIcon fontSize="medium" />
        <ClientIdentity variant="body1">{sipClient?.name}</ClientIdentity>
      </CallInfoLabel>
      {callStartedAt && (
        <CallInfoLabel>
          <TimerIcon fontSize="medium" />
          <Timer startedAt={callStartedAt} />
        </CallInfoLabel>
      )}
    </CallInfoContainer>
  );
};
