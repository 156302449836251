import dateFnsEnLocale from 'date-fns/locale/en-US';
import dateFnsUkLocale from 'date-fns/locale/uk';
import {MRT_Localization_EN} from 'material-react-table/locales/en';
import {MRT_Localization_UK} from 'material-react-table/locales/uk';

import UnitedKingdomFlag from '../components/layouts/components/select-locale/icons/GB-flag.svg';
import UkrainianFlag from '../components/layouts/components/select-locale/icons/UA-flag.svg';
import enMessages from './en/messages.json';
import ukMessages from './uk/messages.json';

export type LocaleType = keyof typeof translations;

export const defaultLocale: LocaleType = 'uk';

export const translations = {
  uk: {
    localeName: 'Українська',
    messages: ukMessages,
    icon: UkrainianFlag,
    MRT_Localization: MRT_Localization_UK,
    dateFnsLocale: dateFnsUkLocale,
  },
  en: {
    localeName: 'English',
    messages: enMessages,
    icon: UnitedKingdomFlag,
    MRT_Localization: MRT_Localization_EN,
    dateFnsLocale: dateFnsEnLocale,
  },
};
