import {PermissionValueType} from '@horn1/api';
import {UserDto} from 'api/rest/authorization';
import {createContext} from 'react';
import noop, {noopAsync} from 'utils/noop';

export type CurrentUser = {
  id: string;
  email: string;
  permissions: Set<PermissionValueType>;
  username: string;
  firstName: string;
  lastName: string;
  patronim: string | null;
};

export type HasPermission = (
  permission: PermissionValueType | PermissionValueType[],
) => boolean;

export type UserContextMethods = {
  login(options: {username: string; password: string}): Promise<void>;
  logout(): void | Promise<void>;
  sync(): void | Promise<void>;
  setUser: ((user: UserDto) => void) | null;
};

type UserContextValue = [
  CurrentUser | null | undefined,
  UserContextMethods,
  HasPermission,
];

const UserContext = createContext<UserContextValue>([
  null,
  {
    login: noopAsync,
    logout: noop,
    sync: noop,
    setUser: null,
  },
  permission => false,
]);

export default UserContext;
