import CallIcon from '@mui/icons-material/Call';
import CallEndIcon from '@mui/icons-material/CallEnd';
import PhoneCallback from '@mui/icons-material/PhoneCallback';
import {Box} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import useOptions from 'hooks/useOptions';
import {useTranslation} from 'locales/useTranslation';
import {FC} from 'react';
import ringtoneSound from 'sounds/ringtone.mp3';

import {IncomingCallDialogProps} from '../../SipStatus.models';
import {DialogTitle} from '../styled';
import * as Styled from './IncomingDialog.styled';

export const IncomingCallDialog: FC<IncomingCallDialogProps> = ({
  answer,
  reject,
  remoteIdentity,
  clientName,
  isNumberInBlackList,
  clientAddress,
  callPriority,
}) => {
  const t = useTranslation();
  const [settings] = useOptions();

  const isClientInBlacklist =
    settings?.highlightBlacklistClient && isNumberInBlackList;

  const isPrioritizedCall = callPriority && settings?.callPrioritySetting.use;

  const prioritizedCallText =
    settings?.callPrioritySetting.text !== ''
      ? settings?.callPrioritySetting.text
      : t.setting.general.callPrioritySettingText;

  return (
    <Dialog open>
      <DialogTitle>
        <PhoneCallback fontSize="large" color="success" />
        {t.labels.incomingCall}
      </DialogTitle>

      {isPrioritizedCall && (
        <Box m={1} p={1} sx={{border: '2px solid red', textAlign: 'center'}}>
          <Typography sx={{color: 'red', textTransform: 'uppercase'}}>
            {prioritizedCallText}
          </Typography>
        </Box>
      )}

      <Styled.DialogContent>
        {remoteIdentity && (
          <Styled.Typography isClientInBlacklist={isClientInBlacklist}>
            {remoteIdentity}
          </Styled.Typography>
        )}
        {clientName && (
          <Styled.Typography isClientInBlacklist={isClientInBlacklist}>
            {clientName}
          </Styled.Typography>
        )}
        {clientAddress && (
          <Styled.Typography isClientInBlacklist={isClientInBlacklist}>
            {clientAddress}
          </Styled.Typography>
        )}
      </Styled.DialogContent>

      <Styled.DialogActions>
        <Button
          variant="contained"
          color="success"
          onClick={answer}
          endIcon={<CallIcon />}
        >
          {t.action.answer}
        </Button>

        <Button
          color="error"
          variant="contained"
          onClick={reject}
          endIcon={<CallEndIcon />}
        >
          {t.action.reject}
        </Button>

        <audio src={ringtoneSound} autoPlay loop />
      </Styled.DialogActions>
    </Dialog>
  );
};
