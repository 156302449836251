import {
  createDateYMDFormat,
  DEFAULT_POSTPROCESSING_TIME,
  DEFAULT_TIME_FOR_REJECT,
} from '@horn1/utils';
import OptionsContext, {Options} from 'contexts/OptionsContext';
import useUser from 'hooks/useUser';
import {useTranslation} from 'locales/useTranslation';
import {ReactNode, useEffect, useState} from 'react';
import {useCallback} from 'react';
import fetcher from 'utils/fetcher';

import {useApiErrors} from '../../hooks/useApiErrors';
import {useSnackBar} from '../../hooks/useSnackBar';

interface OptionsProviderProps {
  children: ReactNode;
}

const createDefaultDates = (month: number, day: number) => {
  return createDateYMDFormat(new Date(new Date().getFullYear(), month, day))
    .toISOString()
    .split('T')[0];
};

export default function OptionsProvider({children}: OptionsProviderProps) {
  const t = useTranslation();

  const [options, setOptions] = useState<Options>({
    controlMode: false,
    postProcessing: DEFAULT_POSTPROCESSING_TIME,
    timeOfRejectStatus: DEFAULT_TIME_FOR_REJECT,
    showPendingApplications: false,
    highlightBlacklistClient: false,
    workShiftHours: 8,
    indicatorsSubmissionDates: {
      start: createDefaultDates(new Date().getMonth(), 28),
      end: createDefaultDates(new Date().getMonth() + 1, 3),
    },
    callPrioritySetting: {
      use: true,
      text: t.setting.general.callPrioritySettingText,
    },
    referenceIndicator: 3000,
  });

  const [user] = useUser();
  const {showSnackBar} = useSnackBar();
  const [getApiErrorMessage] = useApiErrors();

  useEffect(() => {
    if (!user) {
      return;
    }

    fetcher<Options>('/api/v1/options')
      .then(options => {
        setOptions(current => ({...current, ...options}));
      })
      .catch(error => {
        showSnackBar(getApiErrorMessage(error.message), {variant: 'error'});
      });
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  const handlerOptionsChange = useCallback((value: Options) => {
    setOptions(currentOptions => ({...currentOptions, ...value}));
  }, []);

  return (
    <OptionsContext.Provider value={[options, handlerOptionsChange]}>
      {children}
    </OptionsContext.Provider>
  );
}
