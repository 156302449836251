import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import {CSSObject, styled, Theme} from '@mui/material';
import Button from '@mui/material/Button';

import {ToggleChatButtonStyleProps} from './ToggleChat.types';

const setAdditionalButtonStyles = (theme: Theme): CSSObject => ({
  backgroundColor: theme.palette.primary.main,

  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
});

export const ToggleChatButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'isModalOpen',
})<ToggleChatButtonStyleProps>(({theme, isModalOpen}) => ({
  cursor: 'pointer',
  aspectRatio: '1',
  borderRadius: '50%',
  color: theme.palette.primary.main,
  minWidth: '56px',
  maxWidth: '56px',

  ...(isModalOpen && setAdditionalButtonStyles(theme)),
}));

export const ChatIcon = styled(ChatBubbleIcon)({
  opacity: 0.7,

  '&:hover': {
    opacity: 1,
  },
});
