import DateFnsUtils from '@date-io/date-fns';
import FormatIndentIncreaseIcon from '@mui/icons-material/FormatIndentIncrease';
import MenuIcon from '@mui/icons-material/Menu';
import {Portal} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DraggableCallAction} from 'components/sip-status/components';
import DateFnsLocaleContext from 'contexts/DateFnsLocaleContext';
import {useLocale} from 'hooks/use-locale/useLocale';
import {useSnackBar} from 'hooks/useSnackBar';
import useUser from 'hooks/useUser';
import {translations} from 'locales/translations';
import {useTranslation} from 'locales/useTranslation';
import React, {
  Suspense,
  useCallback,
  useMemo,
  useReducer,
  useState,
} from 'react';
import {SWRConfig} from 'swr';
import {ApiError} from 'utils/fetcher';

import {DraggableChats} from '../../chat/draggable-chats/DraggableChats';
import {Menu, MobileDrawer, Toolbar} from '../components';
import * as Styled from './styled';

const MainWrapper: React.FC = ({children}) => {
  const {showSnackBar} = useSnackBar();
  const t = useTranslation();
  const [user] = useUser();
  const [, , getLocale] = useLocale();

  const [fixedMenu, setFixedMenu] = useState(false);

  const [open, setOpen] = useState(false);
  const [openMobileMenu, toggleMobileMenu] = useReducer(state => !state, false);

  const handleOpenMobileMenu = useCallback(() => {
    toggleMobileMenu();
  }, []);

  const swrConfig = useMemo(
    () => ({
      onError(error: unknown) {
        if (error instanceof ApiError) {
          if (error.statusCode >= 500) {
            showSnackBar(t.errors.error500, {variant: 'error'});
          }
        }
      },
    }),
    [t.errors], // eslint-disable-line react-hooks/exhaustive-deps
  );

  const locale = getLocale();

  const dateFnsLocale = useMemo(
    () => translations[locale].dateFnsLocale,
    [locale],
  );

  return (
    <SWRConfig value={swrConfig}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        utils={DateFnsUtils}
        locale={dateFnsLocale}
      >
        <DateFnsLocaleContext.Provider value={dateFnsLocale}>
          <Styled.Root>
            <DraggableCallAction />
            <CssBaseline />
            <Styled.AppBar position="sticky" open={open}>
              <Toolbar
                icon={
                  <Styled.MobileMenuIcon
                    edge="start"
                    color="inherit"
                    onClick={handleOpenMobileMenu}
                    size="large"
                  >
                    <MenuIcon />
                  </Styled.MobileMenuIcon>
                }
              />
            </Styled.AppBar>

            <Styled.DesktopMenu>
              <Styled.Drawer
                variant="permanent"
                onMouseEnter={() => {
                  setOpen(true);
                }}
                onMouseLeave={() => !fixedMenu && setOpen(false)}
                anchor="left"
                open={open}
              >
                <Styled.DrawerHeader>
                  <IconButton
                    onClick={() => setFixedMenu(current => !current)}
                    size="large"
                  >
                    <FormatIndentIncreaseIcon
                      color={fixedMenu ? 'primary' : 'inherit'}
                    />
                  </IconButton>
                </Styled.DrawerHeader>
                <Divider />

                <Menu open={open} />
              </Styled.Drawer>
            </Styled.DesktopMenu>

            <Styled.MobileDrawer>
              <MobileDrawer
                open={openMobileMenu}
                handleClose={handleOpenMobileMenu}
              />
            </Styled.MobileDrawer>

            <Portal>{user && <DraggableChats fixedMenu={fixedMenu} />}</Portal>
            <Styled.Main fixed={fixedMenu}>
              <Suspense
                fallback={
                  <Styled.ProgressCont>
                    <CircularProgress />
                  </Styled.ProgressCont>
                }
              >
                {children}
              </Suspense>
            </Styled.Main>
          </Styled.Root>
        </DateFnsLocaleContext.Provider>
      </LocalizationProvider>
    </SWRConfig>
  );
};

export default MainWrapper;
