import {FC} from 'react';

import {MessageBoxWrapper} from './MessageBox.styled';
import {MessageBoxProps} from './MessageBox.types';

export const MessageBox: FC<MessageBoxProps> = ({
  children,
  direction,
  onContextMenu,
}) => {
  return (
    <MessageBoxWrapper direction={direction} onContextMenu={onContextMenu}>
      {children}
    </MessageBoxWrapper>
  );
};
