import {HttpStatusCode} from '@horn1/api';
import {Button, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Header from 'components/Header';
import React, {useMemo} from 'react';
import {FormattedMessage} from 'react-intl';
import {useHistory} from 'react-router-dom';

interface ErrorCodeProps {
  code: HttpStatusCode;
}

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '50px',
    display: 'grid',
    gap: '20px',
    placeItems: 'center',
  },
  button: {
    fontSize: '20px',
  },
  message: {
    paddingInline: '20px',
    textAlign: 'center',
    maxWidth: '1000px',
  },
}));

const ErrorCode: React.FC<ErrorCodeProps> = ({code = 404}) => {
  const classes = useStyles();
  const history = useHistory();

  const errorMessage = useMemo(() => {
    switch (code) {
      case 403:
        return {
          id: 'page403.message',
          defaultMessage: 'Method not allowed',
        };
      case 401:
        return {
          id: 'page401.message',
          defaultMessage: 'Unauthorized',
        };
      default:
        return {
          id: 'page404.message',
          defaultMessage: 'Page Not Found',
        };
    }
  }, [code]);

  return (
    <>
      <Header title={`${code} ${HttpStatusCode[code]}`} />

      <div className={classes.root}>
        <Typography variant="h1">{`${code}`}</Typography>
        <h2 className={classes.message}>
          <FormattedMessage
            id={errorMessage.id}
            defaultMessage={errorMessage.defaultMessage}
          />
        </h2>
        <div className={classes.button}>
          {code === HttpStatusCode.Forbidden && (
            <Button
              className={classes.button}
              onClick={() => history.replace('/')}
              color="primary"
            >
              <FormattedMessage
                id="page403.button"
                defaultMessage="Go to home page"
              />
            </Button>
          )}
          {code === HttpStatusCode.Unauthorized && (
            <Button
              className={classes.button}
              onClick={() => history.replace('/login')}
              color="primary"
            >
              <FormattedMessage
                id="page401.button"
                defaultMessage="Go to home page"
              />
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default ErrorCode;
