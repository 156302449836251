import {lazy} from 'react';

import {Route} from '../models';
import publicPath from './path';

const AcceptInvite = lazy(() => import('modules/AcceptInvite'));
const Login = lazy(() => import('modules/Login'));
const ForgotPassword = lazy(() => import('modules/ForgotPassword'));
const ResetPassword = lazy(() => import('modules/ResetPassword'));

const publicRoutes: Route[] = [
  {
    path: publicPath.login,
    component: Login,
  },
  {
    path: publicPath.acceptInvite,
    component: AcceptInvite,
  },
  {
    path: publicPath.forgotPassword,
    component: ForgotPassword,
  },
  {
    path: publicPath.resetPassword,
    component: ResetPassword,
  },
];

export default publicRoutes;
