import React from 'react';

type FormProps = {
  children?: React.ReactNode;
  onSubmit: () => void;
} & React.DetailedHTMLProps<
  React.FormHTMLAttributes<HTMLFormElement>,
  HTMLFormElement
>;

const Form = ({onSubmit, ...rest}: FormProps) => {
  return (
    <form
      {...rest}
      onSubmit={e => {
        e.preventDefault();
        onSubmit();
      }}
    />
  );
};

export default Form;
