import {CSSObject, styled, Theme} from '@mui/material';
import {DirectionEnum} from 'models/Direction';

import {MessageWrapperProps} from './MessageBox.types';

const setAdditionalAuthorStyles = (theme: Theme): CSSObject => ({
  backgroundColor: '#5699f0e2',
  alignSelf: 'flex-end',
  borderRadius: '12px 12px 0px 12px',
});

const setAdditionalGuestsStyles = (): CSSObject => ({
  backgroundColor: '#e0e0e0',
  alignSelf: 'flex-start',
  borderRadius: '12px 12px 12px 0px',
});

export const MessageBoxWrapper = styled('div', {
  shouldForwardProp: prop => prop !== 'direction',
})<MessageWrapperProps>(({theme, direction}) => ({
  color: '#000',
  padding: '8px 15px',
  maxWidth: '300px',
  overflow: 'hidden',
  wordBreak: 'break-word',

  ...(direction === DirectionEnum.OUT
    ? setAdditionalAuthorStyles(theme)
    : setAdditionalGuestsStyles()),
}));
