import {styled} from '@mui/material';

export const CreateButton = styled('div')(({theme}) => ({
  cursor: 'pointer',
  padding: '5px 10px',
  margin: '0 10px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  transition: 'all 400ms ease-in-out',
  borderRadius: '10px',
  height: '50px',

  '&:hover': {
    backgroundColor: theme.palette.primary.main,

    '& > *': {
      color: '#fff',
    },
  },
}));
