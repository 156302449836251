import HintProvider from 'modules/calls/HintProvider';
import React from 'react';
import {IntlProvider} from 'react-intl';

import {LocaleType, translations} from '../../locales/translations';
import DraggableProvider from './DraggableProvider';
import {LocaleProvider} from './LocaleProvider';
import OptionsProvider from './OptionsProvider';
import ServiceProvider from './ServiceProvider';
import MuiThemeProvider from './ThemeProvider';
import ToastsProvider from './ToastsProvider';
import UserProvider from './UserProvider';

const AppProviders: React.FC<{
  children: React.ReactNode;
  locale: LocaleType;
  handleLocaleChange: (locale: LocaleType) => void;
}> = ({children, locale, handleLocaleChange}) => {
  const messages = translations[locale].messages;

  return (
    <ServiceProvider>
      <IntlProvider locale={locale} messages={messages}>
        <LocaleProvider locale={locale} handleLocaleChange={handleLocaleChange}>
          <ToastsProvider>
            <UserProvider>
              <OptionsProvider>
                <MuiThemeProvider>
                  <DraggableProvider>
                    <HintProvider>{children}</HintProvider>
                  </DraggableProvider>
                </MuiThemeProvider>
              </OptionsProvider>
            </UserProvider>
          </ToastsProvider>
        </LocaleProvider>
      </IntlProvider>
    </ServiceProvider>
  );
};

export default AppProviders;
