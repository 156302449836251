import {Translate} from '@mui/icons-material';
import {FormControl, MenuItem} from '@mui/material';
import {LocaleContext} from 'contexts/LocaleContext';
import {LocaleType, translations} from 'locales/translations';
import React, {FC, useContext} from 'react';

import {
  ChangeLocaleList,
  LocaleBoxWrapper,
  LocaleFlagIcon,
  LocaleSelect,
} from './SelectLocale.styled';

export const SelectLocale: FC = () => {
  const {locale, setLocale} = useContext(LocaleContext);

  return (
    <LocaleBoxWrapper>
      <Translate />
      <FormControl variant="standard">
        <LocaleSelect
          value={locale}
          onChange={event => setLocale(event.target.value as LocaleType)}
          renderValue={selected => {
            const selectedLocale =
              translations[selected as keyof typeof translations];

            return (
              <LocaleFlagIcon
                src={selectedLocale.icon}
                alt={selectedLocale.localeName}
              />
            );
          }}
        >
          {Object.entries(translations).map(([key, value]) => (
            <MenuItem key={key} value={key}>
              <ChangeLocaleList>
                <LocaleFlagIcon src={value.icon} alt={value.localeName} />
                {value.localeName}
              </ChangeLocaleList>
            </MenuItem>
          ))}
        </LocaleSelect>
      </FormControl>
    </LocaleBoxWrapper>
  );
};
