import HintContext, {Hint} from 'contexts/HintContext';
import React, {useState} from 'react';

export const nullableTopic = {
  id: null,
  name: 'string',
  order: 12,
  isLeaf: false,
  hasResult: false,
  request: 'string',
  response: 'string',
};

export interface ITopic {
  id: number | null;
  name: string;
  order: number;
  isLeaf: boolean;
  hasResult: boolean;
  request?: string;
  response?: string;
  children?: ITopic[];
}

const HintProvider: React.FC = ({children}) => {
  const [hints, setHints] = useState<Hint[]>([
    {applicationId: 0, topic: nullableTopic},
  ]);

  return (
    <HintContext.Provider value={[hints, setHints]}>
      {children}
    </HintContext.Provider>
  );
};

export default HintProvider;
