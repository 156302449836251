import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {Box, styled, Typography} from '@mui/material';
import {InputLabel as MuiInputLabel, Select as MuiSelect} from '@mui/material';
import MuiButton from '@mui/material/Button';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

const DialogTitle = styled(MuiDialogTitle)({
  textAlign: 'center',
  display: 'grid',
  alignItems: 'center',
  justifyItems: 'center',
  gap: '20px',
});

const CallActionButton = styled(MuiButton)(({theme}) => ({
  display: 'flex',
  gap: '10px',

  [theme.breakpoints.down('md')]: {
    width: '100%',
    'span:first-of-type': {
      display: 'none',
    },
  },
}));

const OutCallConnecting = styled('span')({
  color: 'white',
  margin: 'auto',
});

const DraggableContainer = styled(Box)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '20px',
  right: '130px',
  backgroundColor: theme.palette.primary.main,
  zIndex: 1300,
  cursor: '-webkit-grab',
  padding: '10px 20px',
  borderRadius: '10px',
  position: 'fixed',

  [theme.breakpoints.down('md')]: {
    position: 'fixed',
    display: 'grid',
    justifyContent: 'space-between',
  },

  transition: 'box-shadow 0.3s ease-in-out',

  '&:hover': {
    boxShadow: '0 0 10px rgba(255, 255, 255, 0.5)',
  },
}));

const CallContainer = styled(Box)<{toggleSize?: boolean}>(({toggleSize}) => ({
  display: 'flex',
  flexDirection: toggleSize ? 'column-reverse' : 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
  width: '100%',
  gap: toggleSize ? '0' : '20px',
}));

const StyledBox = styled(Box)<{toggleSize: boolean}>(({toggleSize}) => ({
  display: 'flex',
  flexDirection: toggleSize ? 'column' : 'row',
  alignItems: 'center',
  gap: '20px',
}));

const CallToDispatcherContainer = styled(Box)({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  gap: '10px',
});

const InputLabel = styled(MuiInputLabel)({
  color: '#fff',
  '&.Mui-focused': {
    color: '#fff',
  },
});

const Select = styled(MuiSelect)({
  color: 'white',
  zIndex: 10000,
  '& .MuiSvgIcon-root': {
    color: '#fff',
  },
  '&:before': {
    borderColor: '#fff',
  },
});

const ArrowIcon = styled(KeyboardArrowDownIcon)<{down: boolean}>(({down}) => ({
  transform: down ? 'rotate(180deg)' : 'unset',
  color: '#fff',
}));

const WhiteIconButton = styled(IconButton)({
  backgroundColor: '#fff',

  '&:hover': {
    backgroundColor: '#fff',
  },
});

const CallInfoContainer = styled(Box)<{toggleSize?: boolean}>(
  ({theme, toggleSize}) => ({
    display: 'flex',
    flexDirection: toggleSize ? 'column' : 'row',
    gap: '10px',
    alignItems: 'center',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      justifyContent: 'space-between',
    },
  }),
);

const ClientIdentity = styled(Typography)(({theme}) => ({
  whiteSpace: 'nowrap',

  [theme.breakpoints.down('md')]: {
    whiteSpace: 'unset',
  },
}));

const CallInfoLabel = styled(Box)({
  display: 'flex',
  gap: '5px',
  alignItems: 'center',
  color: '#fff',
});

const CallActionContainer = styled(Box)({
  display: 'flex',
  gap: '20px',
  width: '100%',
  justifyContent: 'center',
});

export {
  ArrowIcon,
  CallActionButton,
  CallActionContainer,
  CallContainer,
  CallInfoContainer,
  CallInfoLabel,
  CallToDispatcherContainer,
  ClientIdentity,
  DialogTitle,
  DraggableContainer,
  InputLabel,
  OutCallConnecting,
  Select,
  StyledBox,
  WhiteIconButton,
};
