import React from 'react';
import Helmet from 'react-helmet';

interface HeaderProps {
  children?: React.ReactNode;
  title: string | React.ReactNode;
}

export default function Header({title, children}: HeaderProps) {
  return (
    <Helmet>
      <title>{title}</title>
      {children && children}
    </Helmet>
  );
}
