import {DEFAULT_POSITION} from 'contexts/DraggableContext';
import useUser from 'hooks/useUser';
import {isEqual} from 'lodash';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {
  DraggableData,
  DraggableEvent,
  DraggableEventHandler,
} from 'react-draggable';

export const useDraggable = () => {
  const [position, setPosition] = useState(DEFAULT_POSITION);

  const [user] = useUser();

  const setDraggablePosition: DraggableEventHandler = useCallback(
    (e: DraggableEvent, data: DraggableData) => {
      setPosition(current => ({...current, x: data.x, y: data.y}));

      localStorage.setItem(
        `draggablePosition-${user?.username}`,
        JSON.stringify({x: data.x, y: data.y}),
      );
    },
    [user],
  );

  const setToDefault = useCallback(() => {
    setPosition(DEFAULT_POSITION);

    localStorage.removeItem(`draggablePosition-${user?.username}`);
  }, [user]);

  useEffect(() => {
    if (!user) {
      return;
    }

    const jsonPosition = localStorage.getItem(
      `draggablePosition-${user?.username}`,
    );

    const position = jsonPosition && JSON.parse(jsonPosition);

    if (position) {
      setPosition(current => ({...current, ...position}));
    }
  }, [user]);

  const showPin = useMemo(
    () => !isEqual(position, DEFAULT_POSITION),
    [position],
  );

  return {position, setDraggablePosition, showPin, setToDefault};
};
