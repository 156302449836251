import noop from 'lodash/noop';
import React, {createContext} from 'react';

export const nullableTopic = {
  id: null,
  name: 'string',
  order: 12,
  isLeaf: false,
  hasResult: false,
  request: 'string',
  response: 'string',
};

export interface ITopic {
  id: number | null;
  name: string;
  order: number;
  isLeaf: boolean;
  hasResult: boolean;
  request?: string;
  response?: string;
  children?: ITopic[];
}
export interface Hint {
  applicationId: null | number;
  topic: ITopic;
}
export type SetHint = React.Dispatch<React.SetStateAction<Hint[]>>;

type HintContextType = [Hint[], SetHint];

const HintContext = createContext<HintContextType>([
  [{applicationId: null, topic: nullableTopic}],
  noop,
]);

export default HintContext;
