import {CSSObject, styled} from '@mui/material';

const setAdditionalHeaderContainerStyles = (): CSSObject => ({
  justifyContent: 'space-between',
});

export const HeaderContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'canDeleteChatRoom',
})<{canDeleteChatRoom: boolean}>(({theme, canDeleteChatRoom}) => ({
  display: 'flex',
  width: '100%',
  height: '80px',
  alignItems: 'center',
  padding: '10px',
  backgroundColor: theme.palette.primary.main,

  ...(canDeleteChatRoom && setAdditionalHeaderContainerStyles()),

  '& > *': {
    fontStyle: 'normal',
    fontWeight: 400,
    cursor: 'pointer',
    color: '#A6ADB2',
  },
}));

const setAdditionalRoomInfoStyles = (): CSSObject => ({
  marginLeft: '31%',
});

export const RoomInfoContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'canDeleteChatRoom',
})<{canDeleteChatRoom: boolean}>(({canDeleteChatRoom}) => ({
  display: 'grid',
  textAlign: 'center',

  ...(!canDeleteChatRoom && setAdditionalRoomInfoStyles()),
}));

export const RoomName = styled('span')({
  fontSize: '14px',
  color: '#fff',
  fontWeight: 'bold',
  flexGrow: 1,
  cursor: 'auto',
  height: 20,
});

export const RoomMembers = styled('span')({
  fontSize: '10px',
  color: '#d2d1d3',

  '&:hover': {
    textDecoration: 'underline',
  },
});
