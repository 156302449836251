import {styled} from '@mui/material';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiTypography from '@mui/material/Typography';

export const Typography = styled(MuiTypography, {
  shouldForwardProp: prop => prop !== 'isClientInBlacklist',
})<{isClientInBlacklist?: boolean}>(({theme, isClientInBlacklist}) => ({
  color: isClientInBlacklist
    ? theme.palette.error.main
    : theme.palette.text.primary,
}));

export const DialogContent = styled(MuiDialogContent)(({theme}) => ({
  textAlign: 'center',
}));

export const DialogActions = styled(MuiDialogActions)(({theme}) => ({
  justifyContent: 'space-between',
}));
