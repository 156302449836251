import {styled} from '@mui/material';

export const Root = styled('div')(() => ({
  padding: '16px',
  borderRadius: '10px',
  boxShadow: `rgb(0 0 0 / 10%) 0px 4px 15px 0px,
      rgb(0 0 0 / 10%) 0px 1px 2px 0px,
      rgb(48 71 236 / 50%) 0px 2px 0px 0px inset`,
  backgroundColor: '#ffffff',
  overflowX: 'hidden',
  height: '400px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const Wrapper = styled('div')(() => ({
  display: 'grid',
  justifyItems: 'center',
  gap: '5px',
}));
