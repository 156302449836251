import {PermissionValueType} from '@horn1/api';
import LoadingScreen from 'components/LoadingScreen';
import ErrorCode from 'components/pages/ErrorCodePage';
import UserContext from 'contexts/UserContext';
import React, {useContext} from 'react';
import {Redirect, Route, RouteProps, useLocation} from 'react-router-dom';

interface AuthMiddlewareProps extends RouteProps {
  component: React.FC<Record<string, unknown>>;
  permissions?: PermissionValueType[];
  isPrivate?: boolean;
}

const AuthMiddleware: React.FC<AuthMiddlewareProps> = ({
  component: Component,
  isPrivate = false,
  permissions,
  ...rest
}) => {
  const [user, , hasPermission] = useContext(UserContext);
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={props => {
        if (user === undefined) {
          return <LoadingScreen />;
        }

        if (isPrivate && user === null) {
          sessionStorage.setItem(
            'redirect',
            `${location.pathname}${location.search}`,
          );

          return <Redirect to="/login" />;
        }

        if (permissions?.length) {
          const userHasPermission = permissions.every(permission =>
            hasPermission(permission),
          );

          if (!userHasPermission) {
            return <ErrorCode code={401} />;
          }
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default AuthMiddleware;
