import TimerIcon from '@mui/icons-material/Timer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, {useEffect, useState} from 'react';
import formatTime from 'utils/formatTime';

interface CountDownProps {
  finishAt: Date;
  className: string;
}

const CountDown: React.FC<CountDownProps> = ({finishAt, className}) => {
  const [timer, setTimer] = useState(
    formatTime(finishAt.getTime() - new Date().getTime()),
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(formatTime(finishAt.getTime() - new Date().getTime()));
    }, 500);

    return () => clearInterval(interval);
  }, [finishAt]);

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
        color: '#fff',
      }}
    >
      <TimerIcon fontSize="medium" />
      <Typography variant="body1">{timer}</Typography>
    </Box>
  );
};

export default CountDown;
