import {CSSObject, styled} from '@mui/material';

import {StyledChatContainerProps} from './Chats.types';

export const Wrapper = styled('div')({
  display: 'flex',
  alignItems: 'end',
  position: 'absolute',
  bottom: '0',
  zIndex: 100,
});

export const ChatsRoot = styled('div')(({theme}) => ({
  position: 'relative',
  width: '400px',
  height: '60vh',
  '& *': {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  borderRadius: '10px',
  backgroundColor: '#fff',
  boxShadow: '11px 11px 32px 9px rgba(0,0,0,0.27)',
  overflow: 'hidden',
  '& ::-webkit-scrollbar': {
    width: '7px',
    position: 'absolute',
    zIndex: 2,
  },
  '& ::-webkit-scrollbar-track': {
    background: '#fafafa',
  },
  '& ::-webkit-scrollbar-thumb': {
    background: '#b8b8b8',
    borderRadius: '15px',
  },
  '& ::-webkit-scrollbar-thumb:hover': {
    background: '#c5c2c2',
  },
  '&:before': {
    zIndex: 0,
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '45%',
    width: '100%',
    backgroundColor: theme.palette.primary.main,
  },
}));

export const RootScroll = styled('div')({
  overflowX: 'hidden',
  overflowY: 'auto',
  padding: '20px 15px 0',
  height: '100%',
  position: 'relative',
  '& ::-webkit-scrollbar': {
    position: 'relative',
    zIndex: 2,
  },
});

export const InnerContainer = styled('div')({
  position: 'absolute',
  left: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
  zIndex: 3,
});

export const Content = styled('div')({
  position: 'relative',
  width: '100%',
  display: 'grid',
  gap: 25,
  padding: '15px 10px',
  zIndex: 2,
});

const showContainer = (): CSSObject => ({
  visibility: 'visible',
});

export const ChatsContainer = styled('div', {
  shouldForwardProp: prop => prop !== 'isModalOpen',
})<StyledChatContainerProps>(({isModalOpen}) => ({
  position: 'absolute',
  bottom: '80px',
  left: '0',
  visibility: 'hidden',

  ...(isModalOpen && showContainer()),
}));
