import * as Sentry from '@sentry/browser';
import {Extras} from '@sentry/types/dist/extra';
import React, {Component, ErrorInfo} from 'react';

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
  eventId: string | null;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      eventId: null,
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return {hasError: true};
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (process.env.NODE_ENV === 'development') {
      console.error(error);
    }

    Sentry.withScope(scope => {
      scope.setExtras(errorInfo as unknown as Extras);
      const eventId = Sentry.captureException(error);

      this.setState({eventId});
    });
  }

  render() {
    if (this.state.hasError) {
      const {eventId} = this.state;

      return (
        <div style={{padding: '0.5rem'}}>
          <h3>
            Перепрошуємо за незручності, але якщо Ви бачите це повідомлення, то
            виникла помилка, про яку розробники тепер знають і можуть виправити
            найближчим часом.
          </h3>

          {eventId && (
            <>
              <div>
                Ви можете допомогти нам надіславши відгук з описом ситуації, що
                призвела до помилки чи Вашими припущеннями.
              </div>

              <button onClick={() => Sentry.showReportDialog({eventId})}>
                Надіслати відгук
              </button>

              <div style={{margin: '1rem'}} />
            </>
          )}

          <div>Для продовження роботи спробуйте перезавантажити сторінку.</div>

          <button onClick={this.reloadPage}>Перезавантажити сторінку</button>
        </div>
      );
    }

    return this.props.children;
  }

  reloadPage(): void {
    // eslint-disable-next-line no-restricted-globals
    location.reload();
  }
}

export default ErrorBoundary;
