/* eslint-disable @typescript-eslint/no-empty-interface */
import {StyledEngineProvider, Theme, ThemeProvider} from '@mui/material';
import {classicTheme} from 'themes';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

declare module '@mui/styles' {
  interface DefaultTheme extends Theme {}
}

const MuiThemeProvider: React.FC = ({children}) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={classicTheme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};

export default MuiThemeProvider;
