import {SharedProps, useSnackbar} from 'notistack';
import {useMemo} from 'react';

import {CloseSnackbarAction as closeAction} from '../components/snack-bar/actions/CloseSnackBarAction';

type ShowSnackBarProps = SharedProps;

export const useSnackBar = () => {
  const {enqueueSnackbar} = useSnackbar();

  const showSnackBar = useMemo(() => {
    return (
      message: string | React.ReactNode,
      {action, ...rest}: ShowSnackBarProps,
    ) => {
      enqueueSnackbar(message, {
        action: action ? action : closeAction,
        ...rest,
      });
    };
  }, [enqueueSnackbar]);

  return {showSnackBar};
};
