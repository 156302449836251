import {Permissions} from '@horn1/api';
import {Delete} from '@mui/icons-material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import {TooltipTitle} from 'components/show-tooltip';
import useUser from 'hooks/useUser';
import {useTranslation} from 'locales/useTranslation';
import React, {FC, useMemo, useState} from 'react';

import {ConnectedUsersList} from './connected-users/ConnectedUsersList';
import DeleteChatPopUp from './delete-chat/DeleteChatPopUp';
import {
  HeaderContainer,
  RoomInfoContainer,
  RoomMembers,
  RoomName,
} from './Header.styled';
import {HeaderProps} from './Header.types';

const Header: FC<HeaderProps> = ({
  deleteRoom,
  allUsers,
  roomName,
  connectedUsersId,
  goBackHandler,
}) => {
  const t = useTranslation();
  const [, , hasPermission] = useUser();
  const [isPopUpDelete, setIsPopUpDelete] = useState(false);

  const participantsLabel =
    connectedUsersId.length > 4
      ? t.chat.participantsMore4
      : t.chat.participantsLess4;

  const canDeleteChatRoom = useMemo(() => {
    return hasPermission(Permissions.DeleteChat);
  }, [hasPermission]);

  return (
    <HeaderContainer canDeleteChatRoom={canDeleteChatRoom}>
      <IconButton onClick={goBackHandler} size="large">
        <KeyboardArrowLeft fontSize="small" sx={{color: 'white'}} />
      </IconButton>
      <RoomInfoContainer canDeleteChatRoom={canDeleteChatRoom}>
        <RoomName>{roomName}</RoomName>
        <Tooltip
          arrow
          title={
            <TooltipTitle>
              <ConnectedUsersList
                connectedUsersId={connectedUsersId}
                allUsers={allUsers}
              />
            </TooltipTitle>
          }
          TransitionComponent={Zoom}
          placement="bottom"
        >
          <RoomMembers>
            {participantsLabel}: {connectedUsersId.length}
          </RoomMembers>
        </Tooltip>
      </RoomInfoContainer>

      {canDeleteChatRoom && (
        <Tooltip title={t.chat.chatDelete}>
          <IconButton onClick={() => setIsPopUpDelete(true)} size="large">
            <Delete color="secondary" />
          </IconButton>
        </Tooltip>
      )}

      <DeleteChatPopUp
        isPopUpDelete={isPopUpDelete}
        setIsPopUpDelete={setIsPopUpDelete}
        deleteRoom={deleteRoom}
        roomName={roomName}
      />
    </HeaderContainer>
  );
};

export default Header;
