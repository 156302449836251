import {PermissionValueType} from '@horn1/api';
import useUser from 'hooks/useUser';

interface IUseMenuTabs {
  showTab: (permissionsToCheck: PermissionValueType[]) => boolean;
}

export const useMenuTabs = (): IUseMenuTabs => {
  const [, , hasPermission] = useUser();

  const showTab = (permissionsToCheck: PermissionValueType[]) => {
    return hasPermission(permissionsToCheck);
  };

  return {showTab};
};
