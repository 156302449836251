import CreateIcon from '@mui/icons-material/Create';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'locales/useTranslation';
import {FC} from 'react';

import {ChatBox} from '../../ui';
import {CreateButton} from './CreateRoomButton.styled';
import {OpenFromProps} from './CreateRoomButton.types';

const CreateRoomButton: FC<OpenFromProps> = ({openFormHandler}) => {
  const t = useTranslation();

  return (
    <ChatBox>
      <CreateButton onClick={() => openFormHandler(true)}>
        <Typography
          sx={{
            fontSize: '1rem',
            fontWeight: 'bold',
          }}
        >
          {t.chat.createNewChat}
        </Typography>
        <CreateIcon fontSize="medium" color="primary" />
      </CreateButton>
    </ChatBox>
  );
};

export default CreateRoomButton;
