import {ChatDto, ChatEvents, CreateNewChatDto, UserDto} from '@horn1/api';
import {useApiErrors} from 'hooks/useApiErrors';
import {useSnackBar} from 'hooks/useSnackBar';
import useUser from 'hooks/useUser';
import {FC, useCallback, useEffect, useRef, useState} from 'react';
import socketIOClient from 'socket.io-client';

import ChatsContext from './ChatsContext';

const ChatsProvider: FC = ({children}) => {
  const socketIo = useRef<SocketIOClient.Socket | null>(null);

  const [user] = useUser();
  const {showSnackBar} = useSnackBar();
  const [getApiErrorMessage] = useApiErrors();

  const [isRoomsLoaded, setRoomLoaded] = useState(false);
  const [rooms, setRoomList] = useState<ChatDto[]>([]);
  const [activeRoom, setActiveRoom] = useState<ChatDto | null>(null);
  const [isRoomOpen, setIsRoomOpen] = useState<boolean>(false);
  const [isFormOpen, setOpenForm] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [notifications, setNotification] = useState<number[]>([]);
  const [chatRoomUsers, setChatRoomUsers] = useState<UserDto[]>([]);

  useEffect(() => {
    const connect = () => {
      socketIo.current = socketIOClient({
        path: '/api/v1/chats/ws',
        transports: ['websocket'],
      });

      socketIo.current?.emit(ChatEvents.GetRooms);
    };

    connect();

    const notifications = localStorage.getItem(`notifications-${user?.id}`);

    if (notifications) {
      setNotification(JSON.parse(notifications));
    }

    const currentUserId = user?.id ? +user.id : 0;

    socketIo.current?.on(ChatEvents.GetRooms, (rooms: ChatDto[]) => {
      setRoomList(rooms);
      setRoomLoaded(true);
    });

    socketIo.current?.on(ChatEvents.DeleteRoom, (roomId: number) => {
      setRoomList(current => current.filter(el => el.id !== roomId));
      setNotification(current => current.filter(el => el === roomId));
      setIsRoomOpen(false);
    });

    socketIo.current?.on(ChatEvents.Error, (error: string) => {
      showSnackBar(getApiErrorMessage(error), {variant: 'error'});
    });

    socketIo.current?.on(
      ChatEvents.CreateRoom,
      (body: {room: ChatDto; userId: number}) => {
        if (!body.room) {
          return;
        }

        const {room, userId} = body;

        const isAuthor = currentUserId === userId;

        if (room.usersId.includes(userId)) {
          setRoomList(current => [room, ...current]);
        }

        if (isAuthor) {
          setOpenForm(false);
          setIsRoomOpen(true);
          setActiveRoom(room);
        }
      },
    );

    return () => {
      socketIo.current?.disconnect();
      socketIo.current?.removeAllListeners();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.setItem(
      `notifications-${user?.id}`,
      JSON.stringify(notifications),
    );
  }, [notifications, user?.id]);

  useEffect(() => {
    if (!rooms.length) {
      return;
    }

    socketIo.current?.on(ChatEvents.OpenRoomOnMessage, (roomId: number) => {
      const newRoom = rooms.find(el => el.id === roomId);

      if (newRoom) {
        setActiveRoom(newRoom);
        setIsRoomOpen(true);

        if (!isModalOpen) {
          setIsModalOpen(true);
        }
      }
    });

    socketIo.current?.on(
      ChatEvents.NewMessageInRoom,
      (body: {roomId: number; senderId: number}) => {
        const {senderId, roomId} = body;

        const currentUserId = user?.id ? +user.id : 0;

        const hasRoom = rooms.find(el => el.id === roomId);

        if (
          currentUserId !== senderId &&
          hasRoom &&
          activeRoom?.id !== roomId
        ) {
          setNotification(current => [roomId, ...current]);
        }
      },
    );

    return () => {
      socketIo.current?.off(ChatEvents.OpenRoomOnMessage);
      socketIo.current?.off(ChatEvents.NewMessageInRoom);
    };
  }, [activeRoom?.id, isModalOpen, rooms, user?.id]);

  useEffect(() => {
    const handleChatUsers = (chatUsers: UserDto[]) => {
      setChatRoomUsers(chatUsers);
    };

    if (activeRoom?.id) {
      socketIo.current?.emit(ChatEvents.GetRoomUsers, {chatId: activeRoom.id});
      socketIo.current?.on(ChatEvents.GetRoomUsers, handleChatUsers);
    }

    return () => {
      if (socketIo.current) {
        socketIo.current.off(ChatEvents.GetRoomUsers, handleChatUsers);
      }
    };
  }, [activeRoom?.id]);

  const setRoom = useCallback(
    (id: number | null) => {
      if (id === null) {
        setActiveRoom(null);

        return;
      }

      const newRoom = rooms.find(room => room.id === id);

      if (newRoom) {
        setActiveRoom(newRoom);
        setIsRoomOpen(true);
        setNotification(current => current.filter(el => el !== newRoom.id));
      }
    },
    [rooms],
  );

  const getMessageHistory = useCallback(
    (take: number, skip: number) => {
      socketIo.current?.emit(ChatEvents.GetMessageHistory, {
        chatId: activeRoom?.id,
        take,
        skip,
      });
    },
    [activeRoom?.id],
  );

  const openFormToggle = useCallback((value: boolean) => {
    setOpenForm(value);
  }, []);

  const createRoom = useCallback((body: CreateNewChatDto) => {
    socketIo.current?.emit(ChatEvents.CreateRoom, body);
  }, []);

  const joinRoom = useCallback(() => {
    socketIo.current?.emit(ChatEvents.JoinRoom, {
      room: activeRoom?.id,
    });
  }, [activeRoom?.id]);

  const leaveRoom = useCallback(() => {
    socketIo.current?.emit(ChatEvents.LeaveRoom, activeRoom?.id);
  }, [activeRoom?.id]);

  const deleteRoom = useCallback((roomId: number) => {
    socketIo.current?.emit(ChatEvents.DeleteRoom, roomId);
  }, []);

  const isRoomOpenHandler = useCallback((value: boolean) => {
    setIsRoomOpen(value);
  }, []);

  const modalToggle = useCallback(() => {
    setIsModalOpen(prev => !prev);
  }, []);

  const providerValue = {
    isRoomsLoaded,
    isRoomOpen,
    isFormOpen,
    isModalOpen,
    rooms,
    socketIo,
    activeRoom,
    notifications,
    chatRoomUsers,
    methods: {
      getMessageHistory,
      setRoom,
      joinRoom,
      createRoom,
      deleteRoom,
      leaveRoom,
      isRoomOpenHandler,
      openFormToggle,
      modalToggle,
    },
  };

  return (
    <ChatsContext.Provider value={providerValue}>
      {children}
    </ChatsContext.Provider>
  );
};

export default ChatsProvider;
