import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {Badge} from '@mui/material';
import React, {FC} from 'react';

import {ChatIcon, ToggleChatButton} from './ToggleChat.styled';
import {ToggleChatButtonProps} from './ToggleChat.types';

const ToggleChats: FC<ToggleChatButtonProps> = ({
  toggleChatIcon,
  isModalOpen,
  notifications,
}) => {
  return (
    <>
      <ToggleChatButton isModalOpen={isModalOpen} onClick={toggleChatIcon}>
        {isModalOpen ? (
          <KeyboardArrowDownIcon fontSize="large" sx={{color: 'white'}} />
        ) : (
          <Badge badgeContent={notifications.length} color="secondary">
            <ChatIcon fontSize="large" />
          </Badge>
        )}
      </ToggleChatButton>
    </>
  );
};

export default React.memo(ToggleChats);
