/* eslint-disable react-hooks/exhaustive-deps */
import {ChatEvents, DeleteMessageDto, MessageDto} from '@horn1/api';
import {useMemo} from 'react';
import {useCallback} from 'react';
import {useEffect, useState} from 'react';

import useChatsRooms from './useChats';

const useRoom = () => {
  const [messages, setMessages] = useState<MessageDto[]>([]);
  const [count, setCount] = useState<number>(0);
  const [isMessageLoaded, setMessageLoaded] = useState(false);

  const {socketIo, activeRoom, methods} = useChatsRooms();
  const {joinRoom, leaveRoom, getMessageHistory} = methods;

  useEffect(() => {
    joinRoom();
    getMessageHistory(40, 0);

    return () => {
      leaveRoom();
    };
  }, [getMessageHistory, joinRoom, leaveRoom]);

  useEffect(() => {
    socketIo.current?.on(
      ChatEvents.GetMessageHistory,
      (body: {messages: MessageDto[]; roomId: number; count: number}) => {
        const {messages, count} = body;

        setCount(count);

        setMessageLoaded(true);

        if (!messages.length) {
          setMessages(messages);
        } else {
          setMessages(current => [...current, ...messages]);
        }
      },
    );

    if (isMessageLoaded) {
      socketIo.current?.on(ChatEvents.SendMessage, (message: MessageDto) => {
        if (message.chatId === activeRoom?.id) {
          setMessages(current => [message, ...current]);
          setCount(current => current + 1);
        }
      });
    }

    socketIo.current?.on(ChatEvents.DeleteMessage, (messageId: number) => {
      setMessages(current =>
        current.filter(message => message.id !== messageId),
      );
      setCount(current => current - 1);
    });

    return () => {
      socketIo.current?.off(ChatEvents.SendMessage);
      socketIo.current?.off(ChatEvents.DeleteMessage);
      socketIo.current?.off(ChatEvents.GetMessageHistory);
    };
  }, [activeRoom?.id, isMessageLoaded]);

  const deleteMessage = useCallback((body: DeleteMessageDto) => {
    socketIo.current?.emit(ChatEvents.DeleteMessage, body);
  }, []);

  const sendMessage = useCallback((message: MessageDto) => {
    socketIo.current?.emit(ChatEvents.SendMessage, message);
  }, []);

  const getMoreMessages = useCallback((messages: MessageDto[]) => {
    getMessageHistory(40, messages.length);
  }, []);

  const hasMoreMessage = useMemo(
    () => messages.length < count,
    [messages.length, count],
  );

  return {
    messages,
    deleteMessage,
    sendMessage,
    getMoreMessages,
    hasMoreMessage,
  };
};

export default useRoom;
