import CallEndIcon from '@mui/icons-material/CallEnd';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import PhonePausedIcon from '@mui/icons-material/PhonePaused';
import {useTranslation} from 'locales/useTranslation';
import {DirectionEnum} from 'models/Direction';
import {FC, useEffect, useMemo, useReducer} from 'react';

import {CallActionsProps} from '../SipStatus.models';
import {CallActionButton, CallActionContainer} from './styled';

export const CallActions: FC<CallActionsProps> = ({
  endCall,
  holdCall,
  unHoldCall,
  isOnHold,
  callDirection,
}) => {
  const [iconTimeSwitcher, iconToggler] = useReducer(
    direction => !direction,
    false,
  );

  const t = useTranslation();

  const callDirectionIcon = useMemo(() => {
    return callDirection === DirectionEnum.IN ? (
      <CallReceivedIcon fontSize="medium" color="inherit" />
    ) : (
      <CallMadeIcon fontSize="medium" color="inherit" />
    );
  }, [callDirection]);

  useEffect(() => {
    const interval = setInterval(() => {
      iconToggler();
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <CallActionContainer>
      <CallActionButton
        onClick={isOnHold ? unHoldCall : holdCall}
        color={isOnHold ? 'warning' : 'info'}
        variant="contained"
      >
        <span>{t.action.holdCall}</span>
        {iconTimeSwitcher ? (
          <PhonePausedIcon fontSize="medium" />
        ) : (
          callDirectionIcon
        )}
      </CallActionButton>
      <CallActionButton onClick={endCall} color="secondary" variant="contained">
        <span>{t.action.endCall}</span>
        <CallEndIcon fontSize="medium" />
      </CallActionButton>
    </CallActionContainer>
  );
};
