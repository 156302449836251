import {Dialog} from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useTranslation} from 'locales/useTranslation';
import {FC} from 'react';

import {DeleteChatProps} from './DeleteChat.types';

const DeleteChatPopUp: FC<DeleteChatProps> = ({
  isPopUpDelete,
  setIsPopUpDelete,
  deleteRoom,
  roomName,
}) => {
  const t = useTranslation();

  const handleClose = () => {
    setIsPopUpDelete(false);
  };

  return (
    <Dialog
      open={isPopUpDelete}
      onClose={handleClose}
      sx={{textAlign: 'center'}}
    >
      <DialogTitle>{t.chat.confirmChatDeletion}</DialogTitle>
      <DialogContent sx={{width: '400px'}}>
        <DialogContentText>
          {t.chat.deleteChatWithName(roomName)}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{display: 'flex', justifyContent: 'center', pb: '16px'}}
      >
        <Button variant="contained" onClick={handleClose}>
          {t.action.cancel}
        </Button>
        <Button variant="contained" color="secondary" onClick={deleteRoom}>
          {t.action.delete}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteChatPopUp;
