import MainWrapper from 'components/layouts';
import React from 'react';
import {BrowserRouter, Switch} from 'react-router-dom';

import privateRoutes, {devRouters} from './private/routes';
import publicRoutes from './public/routes';
import AuthMiddleware from './Route';

const AppRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        {publicRoutes.map((route, indx) => (
          <AuthMiddleware
            path={route.path}
            key={`${route}-${indx}`}
            component={route.component}
            exact={route.exact || false}
          />
        ))}
        {devRouters.map((route, indx) => (
          <AuthMiddleware
            path={route.path}
            key={`${route}-${indx}`}
            component={route.component}
            exact={route.exact || false}
            permissions={route.permission}
            isPrivate
          />
        ))}
        <MainWrapper>
          <Switch>
            {privateRoutes.map((route, indx) => (
              <AuthMiddleware
                path={route.path}
                key={`${route}-${indx}`}
                component={route.component}
                exact={route.exact || false}
                permissions={route.permission}
                isPrivate
              />
            ))}
          </Switch>
        </MainWrapper>
      </Switch>
    </BrowserRouter>
  );
};

export default AppRoutes;
