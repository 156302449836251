import {createContext} from 'react';

export interface IServiceSubscription {
  unsubscribe(): void;
}

export interface IServiceContext {
  subscribe(
    name: string,
    loadFunc: () => Promise<unknown>,
  ): IServiceSubscription;
  get(name: string): unknown;
}

const ServiceContext = createContext<IServiceContext>({
  subscribe() {
    return {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      unsubscribe() {},
    };
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  get() {},
});

export default ServiceContext;
