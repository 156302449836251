import {styled} from '@mui/material';
import {Box, Select} from '@mui/material';

export const LocaleBoxWrapper = styled(Box)({
  width: '100px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  textAlign: 'center',
});

export const LocaleSelect = styled(Select)({
  '& .MuiSelect-select': {
    color: 'white',
    borderBottom: '2px solid white',
    display: 'flex',
  },

  '& .MuiSvgIcon-root': {
    fill: 'white',
  },

  '&:hover:not(.Mui-disabled):before': {
    borderBottom: '1px solid white',
  },
});

export const LocaleFlagIcon = styled('img')({
  width: '35px',
  marginRight: '8px',
});

export const ChangeLocaleList = styled('div')({
  display: 'flex',
  alignItems: 'center',
});
