import {Permissions} from '@horn1/api';
import {Grow, Slide} from '@mui/material';
import useUser from 'hooks/useUser';
import React, {FC, useCallback} from 'react';

import {
  ChatsContainer,
  ChatsRoot,
  Content,
  InnerContainer,
  RootScroll,
  Wrapper,
} from './Chats.styled';
import {ChatsProps} from './Chats.types';
import {
  ChatRoom,
  CreateChatForm,
  CreateRoomButton,
  RoomList,
  ToggleChat,
} from './components';
import {EmptyChatList} from './components/ui';
import useChatsRooms from './context/useChats';

const Chats: FC<ChatsProps> = ({isDragging}) => {
  const [, , hasPermissions] = useUser();

  const {
    isRoomsLoaded,
    isRoomOpen,
    rooms,
    methods,
    activeRoom,
    socketIo,
    isFormOpen,
    isModalOpen,
    notifications,
    chatRoomUsers,
  } = useChatsRooms();

  const {
    setRoom,
    deleteRoom,
    createRoom,
    isRoomOpenHandler,
    openFormToggle,
    modalToggle,
  } = methods;

  const openChatHandler = useCallback(() => {
    if (isDragging) {
      return;
    }

    modalToggle();
  }, [isDragging, modalToggle]);

  const closeChatHandler = useCallback(() => {
    setRoom(null);
    isRoomOpenHandler(false);
  }, [setRoom, isRoomOpenHandler]);

  const setRoomHandler = useCallback(
    roomId => {
      setRoom(roomId);
    },
    [setRoom],
  );

  const deleteRoomHandler = useCallback(() => {
    if (!activeRoom?.id) {
      return;
    }

    deleteRoom(activeRoom.id);
    setRoom(null);
  }, [activeRoom?.id, deleteRoom, setRoom]);

  return (
    <Wrapper>
      <ChatsContainer isModalOpen={isModalOpen}>
        {isRoomsLoaded && (
          <Grow in={isModalOpen} timeout={{exit: 0, enter: 400}}>
            <ChatsRoot>
              <Slide in={isRoomOpen} direction="left">
                <InnerContainer>
                  {activeRoom && (
                    <React.Fragment key={activeRoom.id}>
                      <ChatRoom
                        goBackHandler={closeChatHandler}
                        users={chatRoomUsers}
                        activeRoom={activeRoom}
                        deleteRoom={deleteRoomHandler}
                      />
                    </React.Fragment>
                  )}
                </InnerContainer>
              </Slide>
              {hasPermissions(Permissions.CreateChat) && socketIo.current && (
                <Slide in={isFormOpen} direction="left">
                  <InnerContainer>
                    <CreateChatForm
                      socketIo={socketIo}
                      goBackHandler={openFormToggle}
                      createRoom={createRoom}
                    />
                  </InnerContainer>
                </Slide>
              )}
              <RootScroll>
                <Content>
                  {hasPermissions(Permissions.CreateChat) && (
                    <CreateRoomButton openFormHandler={openFormToggle} />
                  )}
                  {rooms?.length ? (
                    <RoomList
                      rooms={rooms}
                      setRoom={setRoomHandler}
                      notifications={notifications}
                    />
                  ) : (
                    <EmptyChatList />
                  )}
                </Content>
              </RootScroll>
            </ChatsRoot>
          </Grow>
        )}
      </ChatsContainer>

      <ToggleChat
        toggleChatIcon={openChatHandler}
        isModalOpen={isModalOpen}
        notifications={notifications}
      />
    </Wrapper>
  );
};

export default Chats;
