import {FC, useEffect, useRef, useState} from 'react';

import * as Styled from './Timer.styled';
import {TimerProps} from './Timer.types';
import {getState} from './Timer.utils';

const Timer: FC<TimerProps> = ({startedAt, timeDifferenceMs = 0}) => {
  const [state, setState] = useState(() =>
    getState(startedAt.getTime(), Date.now() - timeDifferenceMs),
  );

  const reserveStartedAt = useRef<Date | null>(null);

  useEffect(() => {
    const ms = Date.now() - timeDifferenceMs - startedAt.getTime();

    if (ms < 0 && !reserveStartedAt.current) {
      reserveStartedAt.current = new Date();
    } else if (ms > 0 && reserveStartedAt.current) {
      reserveStartedAt.current = null;
    }

    const intervalID = setInterval(() => {
      setState(
        getState(
          reserveStartedAt.current?.getTime() || startedAt.getTime(),
          Date.now() - timeDifferenceMs,
        ),
      );
    }, 1000);

    return () => clearInterval(intervalID);
  }, [startedAt]); // eslint-disable-line react-hooks/exhaustive-deps

  return <Styled.Time ms={state.ms}>{state.time}</Styled.Time>;
};

export default Timer;
