import {SnackbarProvider} from 'notistack';
import React from 'react';

const ToastsProvider: React.FC = ({children}) => {
  return (
    <SnackbarProvider autoHideDuration={2000}>{children}</SnackbarProvider>
  );
};

export default ToastsProvider;
