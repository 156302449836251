import Close from '@mui/icons-material/Close';
import {Divider, Drawer, IconButton} from '@mui/material';
import {makeStyles} from '@mui/styles';
import clsx from 'clsx';
import {MenuProps} from 'components/layouts/models';
import React from 'react';

import Menu from './Menu';

const MOBILE_DRAWER = 300;

const MobileDrawer: React.FC<MenuProps> = ({open, handleClose}) => {
  const classes = useStyles();

  return (
    <Drawer
      variant="temporary"
      anchor="left"
      open={open}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
        }),
      }}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleClose} size="large">
          <Close />
        </IconButton>
      </div>
      <Divider />
      <Menu open={open} />
    </Drawer>
  );
};

export default MobileDrawer;

const useStyles = makeStyles(theme => ({
  drawer: {
    width: MOBILE_DRAWER,
    flexShrink: 0,
  },
  drawerOpen: {
    width: MOBILE_DRAWER,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  drawerPaper: {
    width: MOBILE_DRAWER,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(2),
  },
}));
