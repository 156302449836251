import {makeStyles} from '@mui/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
  grow: {
    flexGrow: 1,
  },
}));

const Expander: React.FC = () => {
  const classes = useStyles();

  return <div className={classes.grow} />;
};

export default Expander;
