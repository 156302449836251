import SendIcon from '@mui/icons-material/SendRounded';
import IconButton from '@mui/material/IconButton';
import {useTranslation} from 'locales/useTranslation';
import {FC} from 'react';
import {Controller, useForm} from 'react-hook-form';

import {MessageInput, Root} from './SendMessage.styled';
import {SendMessageProps} from './SendMessage.types';

const SendMessage: FC<SendMessageProps> = ({sendMessage, chatId}) => {
  const {control, setValue, getValues, watch} = useForm({
    mode: 'onChange',
    defaultValues: {
      message: '',
    },
  });

  const t = useTranslation();
  const messageWatcher = watch('message');

  const handleSubmit = () => {
    const {message} = getValues();

    if (message.trim().length > 0) {
      sendMessage(message.trim(), chatId);
      setValue('message', '');
    }
  };

  return (
    <Root onSubmit={handleSubmit}>
      <Controller
        name="message"
        control={control}
        render={props => (
          <MessageInput
            InputProps={{
              disableUnderline: true,
              endAdornment: (
                <IconButton
                  onClick={handleSubmit}
                  size="large"
                  color="primary"
                  disabled={!messageWatcher.trim()}
                >
                  <SendIcon fontSize="large" />
                </IconButton>
              ),
            }}
            onKeyPress={e => {
              if (e.key === 'Enter' && messageWatcher.trim()) {
                e.preventDefault();
                handleSubmit();
              }
            }}
            {...props}
            placeholder={t.chat.message}
            fullWidth
            multiline={true}
            maxRows={2}
          />
        )}
      />
    </Root>
  );
};

export default SendMessage;
