import React, {FC, useState} from 'react';
import Draggable, {DraggableEvent} from 'react-draggable';

import * as Styled from '../../layouts/main-wrapper/styled';
import Chats from '../Chats';
import ChatsProvider from '../context/ChatsProvider';
import {
  CHAT_DRAGGABLE_BOUNDS,
  PRESS_TIME_UNTIL_DRAG_MS,
} from './DraggableChats.constants';
import {DraggableChatsProps} from './DraggableChats.types';

export const DraggableChats: FC<DraggableChatsProps> = ({fixedMenu}) => {
  const [isDragging, setIsDragging] = useState(false);

  const onStart = () => {
    setTimeout(() => {
      setIsDragging(true);
    }, PRESS_TIME_UNTIL_DRAG_MS);
  };

  const onDrag = (e: DraggableEvent) => {
    if (!isDragging) {
      e.preventDefault();

      return;
    }
  };

  const onStop = () => {
    setTimeout(() => {
      setIsDragging(false);
    }, PRESS_TIME_UNTIL_DRAG_MS);
  };

  return (
    <ChatsProvider>
      <Draggable
        onDrag={onDrag}
        onStart={onStart}
        onStop={onStop}
        bounds={CHAT_DRAGGABLE_BOUNDS}
      >
        <Styled.ChatsContainer fixed={fixedMenu}>
          <Chats isDragging={isDragging} />
        </Styled.ChatsContainer>
      </Draggable>
    </ChatsProvider>
  );
};
