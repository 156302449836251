import 'reflect-metadata';
import './index.css';

import * as Sentry from '@sentry/browser';
import ErrorBoundary from 'components/ErrorBoundary';
import ReactDOM from 'react-dom';

import App from './App';

if (process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
  });
}

const ROOT_ID = 'root';

ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById(ROOT_ID),
);
