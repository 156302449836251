import {CSSObject, styled} from '@mui/material';
import Button from '@mui/material/Button';
import {DirectionEnum} from 'models/Direction';

import {RootPropTypes} from './Message.types';

const setFlexStart = (): CSSObject => ({
  alignItems: 'flex-start',
});

export const MessageRoot = styled('div', {
  shouldForwardProp: prop => prop !== 'rootProps',
})<RootPropTypes>(({rootProps}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  gap: '10px',

  '& > *': {
    padding: 0,
    margin: 0,
    lineHeight: '1em',
  },

  ...(rootProps.direction === DirectionEnum.IN && setFlexStart()),
}));

export const MessageText = styled('p')({
  fontSize: '1rem',
  fontWeight: 400,
});

export const ActionBtnsContainer = styled('div')({
  display: 'grid',
  gap: '5px',
  padding: '5px',
  borderRadius: '15px',
});

const ActionBtnStyles: CSSObject = {
  textTransform: 'initial',
  transition: '0.2s ease-in-out',
  width: '100%',
  outline: '0.5px solid lightgrey',
};

export const DeleteButton = styled(Button)({
  ...ActionBtnStyles,
  background: 'white',
  color: 'red',

  '&:hover': {
    background: 'red',
    color: 'white',
  },
});

export const CopyMsgButton = styled(Button)({
  ...ActionBtnStyles,
  background: 'white',
  color: 'black',

  '&:hover': {
    background: 'lightgrey',
  },
});
