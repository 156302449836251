import {ApplicationDto} from '@horn1/api';
import fetcher from 'utils/fetcher';

import {APPLICATION_URL} from '../config';

const applications = {
  create: async (data: Partial<ApplicationDto>) => {
    return fetcher<ApplicationDto>(APPLICATION_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(data),
    });
  },
  update: async (id: string, data: Partial<ApplicationDto>) => {
    return fetcher<ApplicationDto>(`${APPLICATION_URL}/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(data),
    });
  },
  finish: async (id: string) => {
    return fetcher<ApplicationDto>(`${APPLICATION_URL}/${id}/finish`, {
      method: 'POST',
    });
  },
};

export default applications;
