import {PermissionValueType} from '@horn1/api';
import fetcher from 'utils/fetcher';

import {AUTH_URL, USER_URL} from '../config';

export interface Login {
  username: string;
  password: string;
}

export interface UserDto {
  id: string;
  email: string;
  username: string;
  permissions: PermissionValueType[];
  firstName: string;
  lastName: string;
  patronim: string | null;
}

const authorization = {
  login: (credentials: Login): Promise<void> => {
    return fetcher(`${AUTH_URL}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(credentials),
    });
  },
  sync: () => {
    return fetcher<UserDto>(USER_URL);
  },
  logout: () => {
    return fetcher(`${AUTH_URL}/session`, {method: 'DELETE'});
  },
};

export default authorization;
