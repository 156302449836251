import {styled} from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';

export const Root = styled('div')(() => ({
  position: 'relative',
  backgroundColor: '#fff',
  borderRadius: '10px',
  width: '100%',
  height: '100%',
  zIndex: 1,

  boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.1)',
  '&  >*': {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '26px',
  },
}));

export const Content = styled('div')(() => ({
  paddingBlock: '15px',
  height: 'calc(60vh - 136px)',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column-reverse',
  position: 'relative',
  '&::-webkit-scrollbar': {
    position: 'absolute !important',
    width: '0px !important',
  },
}));

export const InfiniteScrollableContent = styled(InfiniteScroll)({
  display: 'flex',
  flexDirection: 'column-reverse',
  gap: '5px',
  paddingInline: '10px',
  paddingBottom: '10px',
});

export const SendMessageWrapper = styled('div')(({theme}) => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  paddingInline: '10px',
  borderTop: '2px solid',
  borderColor: theme.palette.primary.main,
  '&::-webkit-scrollbar': {
    position: 'absolute !important',
    width: '0px !important',
  },
  overflow: 'auto',
}));

export const NoMessages = styled('span')(() => ({
  margin: 'auto',
  background: '#d2d1d3',
  borderRadius: '50px',
  padding: '5px 15px',
}));
