import twoDigits from './twoDigits';

export default function formatTime(ms: number): string | null {
  if (!isFinite(ms) || ms === null) {
    return null;
  }

  const seconds = Math.floor(ms / 1000);
  const minutes = Math.floor(seconds / 60);

  return `${twoDigits(minutes)}:${twoDigits(seconds - minutes * 60)}`;
}
