import {Alert} from '@mui/material';
import Snackbar, {SnackbarOrigin} from '@mui/material/Snackbar';

import {ErrorSnackbarProps} from '../SipStatus.models';

const anchorOrigin: SnackbarOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

export const ErrorSnackbar: React.FC<ErrorSnackbarProps> = ({
  message,
  open,
  severity = 'warning',
  autoHideDuration = 2000,
  ...props
}) => {
  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={open}
      autoHideDuration={autoHideDuration}
      {...props}
    >
      <Alert severity={severity} variant="filled">
        {message}
      </Alert>
    </Snackbar>
  );
};
