import {styled} from '@mui/material';

export const Room = styled('div')(({theme}) => ({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '5px 10px',
  margin: '0 10px',
  borderRadius: '10px',
  minHeight: '65px',

  transition: 'background-color 400ms ease-in-out, color 400ms',

  '&:hover': {
    backgroundColor: theme.palette.primary.main,

    '& *': {
      color: '#fff',
    },
  },
}));
