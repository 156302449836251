import {TaskDto} from '@horn1/api';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {CircularProgress} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import {Box} from '@mui/system';
import {PendingApplicationsDialogProps} from 'components/sip-status/SipStatus.models';
import {useTranslation} from 'locales/useTranslation';
import React, {FC, useState} from 'react';
import {useHistory} from 'react-router-dom';
import useSWR from 'swr';
import fetcher from 'utils/fetcher';

import ApplicationTask from './ApplicationTask';
import CallTask from './CallTask';
import {AccordionTitle} from './PendingApplicationsDialog.styled';

export const PendingApplicationsDialog: FC<PendingApplicationsDialogProps> = ({
  remoteIdentity,
  call,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const t = useTranslation();
  const history = useHistory();

  const {data: tasks, isLoading} = useSWR<TaskDto[]>(
    remoteIdentity
      ? `/api/v1/tasks/by-phone-number?phoneNumber=${remoteIdentity}`
      : null,
    fetcher,
    {
      onSuccess: data => {
        if (data.length) {
          setDialogOpen(true);

          return;
        }
        handleCreateApplication();
      },
    },
  );

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const handleCreateApplication = () => {
    if (!call) {
      return;
    }

    closeDialog();
    history.push(`/calls/${call.id}`);
  };

  return (
    <Dialog open={dialogOpen}>
      <DialogTitle sx={{display: 'flex', justifyContent: 'center'}}>
        <Button variant="contained" onClick={handleCreateApplication}>
          {t.application.createNewApplication}
        </Button>
      </DialogTitle>

      <DialogContent sx={{textAlign: 'center'}}>
        {isLoading ? (
          <Box sx={{display: 'grid', placeItems: 'center'}}>
            <CircularProgress />
          </Box>
        ) : (
          <Accordion TransitionProps={{mountOnEnter: true}}>
            <AccordionTitle expandIcon={<ExpandMoreIcon />}>
              <Typography>{t.labels.listOfPendingApplications}</Typography>
            </AccordionTitle>
            <AccordionDetails
              sx={{
                maxHeight: '300px',
                overflow: 'auto',
              }}
            >
              <Box sx={{display: 'grid', gap: 2.5}}>
                {tasks?.map(task => {
                  if (task.type === 'call' && task.call) {
                    return (
                      <Box key={`call-${task.call.id}`}>
                        <CallTask call={task.call} closeDialog={closeDialog} />
                      </Box>
                    );
                  } else if (task.type === 'application' && task.application) {
                    return (
                      <Box key={`application-${task.application.id}`}>
                        <ApplicationTask
                          application={task.application}
                          closeDialog={closeDialog}
                        />
                      </Box>
                    );
                  } else {
                    return null;
                  }
                })}
              </Box>
            </AccordionDetails>
          </Accordion>
        )}
      </DialogContent>
    </Dialog>
  );
};
