import {Permissions} from '@horn1/api';
import Link from '@mui/material/Link';
import MuiToolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {makeStyles} from '@mui/styles';
import Expander from 'components/Expander';
import SipStatus from 'components/sip-status/SipStatus';
import useUser from 'hooks/useUser';
import React from 'react';
import {Link as RouterLink} from 'react-router-dom';

import LogoutButton from './LogoutButton';
import {SelectLocale} from './select-locale/SelectLocale';

type ToolbarProps = {
  icon?: React.ReactNode;
};

const ADAPTIVE_BREAKPOINT = 710;

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down(ADAPTIVE_BREAKPOINT)]: {
      display: 'flex',
      flexWrap: 'wrap',
    },
  },
  buttonContainer: {
    display: 'flex',
    gap: 10,
  },
}));

const Toolbar: React.FC<ToolbarProps> = ({icon}) => {
  const [user] = useUser();
  const classes = useStyles();

  return (
    <MuiToolbar className={classes.root}>
      {icon && icon}

      <Typography variant="h6">
        <Link component={RouterLink} to="/" color="inherit">
          HS CallCenter
        </Link>
      </Typography>

      <Expander />

      <div className={classes.buttonContainer}>
        {user && user.permissions.has(Permissions.AcceptIncomingCalls) && (
          <SipStatus />
        )}
        <LogoutButton />
        <SelectLocale />
      </div>
    </MuiToolbar>
  );
};

export default Toolbar;
