import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

const LoadingScreen: React.FC = () => {
  return (
    <Box
      height="calc(100vh - 128px)"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress />
    </Box>
  );
};

export default LoadingScreen;
