import {DeleteMessageDto, MessageDto, UserDto} from '@horn1/api';
import {Zoom} from '@mui/material';
import useRoom from 'components/chat/context/useRoom';
import {CurrentUser} from 'contexts/UserContext';
import useUser from 'hooks/useUser';
import {useTranslation} from 'locales/useTranslation';
import React, {FC, useCallback} from 'react';

import Header from '../header/Header';
import {
  Content,
  InfiniteScrollableContent,
  NoMessages,
  Root,
  SendMessageWrapper,
} from './ChatRoom.styled';
import {ChatRoomProps} from './ChatRoom.types';
import {Message} from './message/Message';
import SendMessage from './send-message/SendMessage';

const ChatRoom: FC<ChatRoomProps> = ({
  users = [],
  goBackHandler,
  activeRoom,
  deleteRoom,
}) => {
  const [user] = useUser();

  const t = useTranslation();

  const {
    messages,
    sendMessage,
    deleteMessage,
    getMoreMessages,
    hasMoreMessage,
  } = useRoom();

  const sendMessageHandler = useCallback(
    (value: string, chatId: number) => {
      const message: MessageDto = {
        value,
        chatId,
      };

      sendMessage(message);
    },
    [sendMessage],
  );

  const deleteMessageHandler = useCallback(
    (messageId: number, authorId: number) => {
      const messagePayload: DeleteMessageDto = {
        chatId: activeRoom.id,
        messageId,
        authorId,
      };

      deleteMessage(messagePayload);
    },
    [deleteMessage, activeRoom.id],
  );

  const getAuthorName = useCallback(
    (users: UserDto[], message: MessageDto): string => {
      const author =
        users.find(user => user.id === message.authorId)?.username ||
        t.chat.unknownAuthor;

      const isAuthor = message.authorId === user?.id;

      return isAuthor ? t.chat.you : author;
    },
    [t.chat.unknownAuthor, t.chat.you, user?.id],
  );

  return (
    <Root key={activeRoom.id}>
      <Header
        deleteRoom={deleteRoom}
        allUsers={users}
        connectedUsersId={activeRoom.usersId}
        goBackHandler={goBackHandler}
        roomName={activeRoom.name}
      />
      <Content id={`scrollableDiv${activeRoom.id}`}>
        <InfiniteScrollableContent
          dataLength={messages.length}
          next={() => getMoreMessages(messages)}
          initialScrollY={10}
          inverse={true}
          hasMore={hasMoreMessage}
          scrollThreshold={0.8}
          scrollableTarget={`scrollableDiv${activeRoom.id}`}
          loader={<></>}
        >
          {messages?.map((message, index) => {
            return message.value !== 'deleted' ? (
              <React.Fragment key={`${message.id}-${activeRoom.id}-${index}`}>
                <Message
                  message={message}
                  getAuthorName={getAuthorName}
                  user={user as CurrentUser}
                  deleteMessage={deleteMessageHandler}
                  users={users}
                />
              </React.Fragment>
            ) : null;
          })}
        </InfiniteScrollableContent>

        {!messages.length && (
          <Zoom in={!messages.length} style={{transitionDelay: '150ms'}}>
            <NoMessages>{t.message.emptyChat}</NoMessages>
          </Zoom>
        )}
      </Content>
      <SendMessageWrapper>
        <SendMessage sendMessage={sendMessageHandler} chatId={activeRoom.id} />
      </SendMessageWrapper>
    </Root>
  );
};

export default ChatRoom;
