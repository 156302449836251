import {FC} from 'react';

import {LocaleContext} from '../../contexts/LocaleContext';
import {LocaleType} from '../../locales/translations';

export const LocaleProvider: FC<{
  locale: LocaleType;
  handleLocaleChange: (locale: LocaleType) => void;
}> = ({children, locale, handleLocaleChange}) => {
  return (
    <LocaleContext.Provider
      value={{
        locale,
        setLocale: handleLocaleChange,
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
};
