import * as yup from 'yup';

const schema = yup.object({
  chatName: yup.string().required('error.chatNameIsRequired'),
  users: yup
    .array()
    .min(2, 'error.choose2MoreUsers')
    .required('error.chooseUser')
    .required('isRequired'),
});

export default schema;

export type Schema = yup.InferType<typeof schema>;
