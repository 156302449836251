import {styled} from '@mui/material';

export const TooltipTitle = styled('div')(({theme}) => ({
  maxHeight: '50vh',
  overflowY: 'auto',
  padding: '4px',
  '&::-webkit-scrollbar': {
    width: '8px',
    backgroundColor: 'rgba(0,0,0,0.2)',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(255,255,255,0.5)',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: 'rgba(255,255,255,0.7)',
  },
  '&::-webkit-scrollbar-thumb:active': {
    backgroundColor: 'rgba(255,255,255,0.9)',
  },
  '&::-webkit-scrollbar-corner': {
    backgroundColor: 'transparent',
  },
}));
