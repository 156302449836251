import applications from './rest/applications';
import authorization from './rest/authorization';

export * from './config';

const api = {
  authorization,
  applications,
};

export default api;
