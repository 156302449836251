/* eslint-disable react-hooks/exhaustive-deps */
import {yupResolver} from '@hookform/resolvers/yup';
import {ChatEvents, UserDto} from '@horn1/api';
import {SendOutlined} from '@mui/icons-material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import {IconButton, TextField} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {useTranslation} from 'locales/useTranslation';
import {FC, useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useIntl} from 'react-intl';
import useSWR from 'swr';
import fetcher from 'utils/fetcher';
import createChatSchema, {Schema} from 'utils/yup-schema/createChat';

import {AllowsUsersField, CreateRoomProps} from './CreateRoom.types';
import {
  CreateChatBtn,
  CreateChatForm,
  FormHeader,
  FormWrapper,
  InputWrapper,
} from './CreateRoomForm.styled';

const CreateRoomForm: FC<CreateRoomProps> = ({
  goBackHandler,
  socketIo,
  createRoom,
}) => {
  const t = useTranslation();
  const intl = useIntl();
  const {control, errors, handleSubmit, setValue, setError, reset, register} =
    useForm<Schema>({
      mode: 'onSubmit',
      reValidateMode: 'onChange',
      defaultValues: {
        users: [],
        chatName: '',
      },
      resolver: yupResolver(createChatSchema),
    });

  const {data: users} = useSWR<UserDto[]>(
    `/api/v1/users?unlimited=true`,
    fetcher,
    {
      onError: e => {
        console.error(e);

        return [];
      },
      revalidateOnFocus: false,
    },
  );

  const onSubmit = handleSubmit(data => {
    const {users, chatName} = data;

    const usersId = users.map(el => el.id);

    const body = {usersId, name: chatName};

    createRoom(body);

    reset();
  });

  useEffect(() => {
    if (!socketIo.current) {
      return;
    }

    const socket = socketIo.current;

    socket.on(ChatEvents.RoomExist, (name: string) => {
      setError('chatName', {message: 'chat.error.already.exist'});
    });

    return () => {
      socket.off(ChatEvents.RoomExist);
      reset();
    };
  }, [socketIo]);

  return (
    <FormWrapper>
      <FormHeader>
        <IconButton onClick={() => goBackHandler(false)} size="large">
          <KeyboardArrowLeft sx={{color: 'white'}} />
        </IconButton>
        <span>{t.chat.newChat}</span>
      </FormHeader>
      <CreateChatForm onSubmit={onSubmit}>
        <Controller
          control={control}
          name="chatName"
          render={({name, onChange}) => (
            <TextField
              label={t.chat.chatName}
              fullWidth
              name={name}
              autoComplete="off"
              inputRef={register()}
              onChange={onChange}
              helperText={
                !!errors.chatName &&
                intl.formatMessage({
                  id: errors.chatName.message,
                  defaultMessage: 'error',
                })
              }
              error={!!errors.chatName}
              variant="outlined"
              placeholder={t.chat.chatName}
            />
          )}
        />
        {users?.length && (
          <Controller
            control={control}
            name="users"
            render={({value}) => (
              <Autocomplete
                options={users}
                getOptionLabel={(user: UserDto) => user.username}
                value={value}
                fullWidth
                multiple
                autoComplete={false}
                disableCloseOnSelect
                onChange={(_, users) => {
                  setValue('users', users, {
                    shouldValidate: true,
                  });
                }}
                renderInput={params => (
                  <InputWrapper>
                    <TextField
                      {...params}
                      variant="outlined"
                      label={t.chat.addOperators}
                      error={!!errors?.users}
                      helperText={
                        errors?.users &&
                        intl.formatMessage({
                          id: (errors?.users as unknown as AllowsUsersField)
                            ?.message,
                          defaultMessage: 'error',
                        })
                      }
                    />
                  </InputWrapper>
                )}
              />
            )}
          />
        )}
        <CreateChatBtn
          type="submit"
          color="primary"
          size="medium"
          variant="contained"
          endIcon={<SendOutlined />}
        >
          {t.chat.makeChat}
        </CreateChatBtn>
      </CreateChatForm>
    </FormWrapper>
  );
};

export default CreateRoomForm;
