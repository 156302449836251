import {AccordionSummary, Box, styled} from '@mui/material';

const AccordionTitle = styled(AccordionSummary)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  fontSize: '18px',
}));

const TaskContent = styled('div')(() => ({
  maxHeight: '200px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
}));

const TaskContainer = styled(Box)(() => ({
  textDecoration: 'none',
  '&:hover': {
    backgroundColor: '#fafafa',
    cursor: 'pointer',
  },
}));

export {AccordionTitle, TaskContainer, TaskContent};
