import {styled} from '@mui/material';

export const Root = styled('div')(() => ({
  padding: '15px 0',
  borderRadius: '10px',
  boxShadow:
    '0px 10px 20px 0px rgba(0,0,0,0.19), 0px 6px 6px 0px rgba(0,0,0,0.23)',
  backgroundColor: '#fff',
  overflowX: 'hidden',
}));
