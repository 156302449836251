import {Permissions} from '@horn1/api';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import {Popover} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useSnackBar} from 'hooks/useSnackBar';
import {useTranslation} from 'locales/useTranslation';
import {DirectionEnum} from 'models/Direction';
import {
  FC,
  MouseEvent,
  useCallback,
  useMemo,
  useReducer,
  useState,
} from 'react';

import {MessageBox} from '../../../ui';
import {ADDITIONAL_LEFT_SPACE} from './Message.consts';
import {
  ActionBtnsContainer,
  CopyMsgButton,
  DeleteButton,
  MessageRoot,
  MessageText,
} from './Message.styled';
import {AnchorPositionType, MessageProps} from './Message.types';
import {MessageCreatedAt} from './message-created-at/MessageCreatedAt';

export const Message: FC<MessageProps> = ({
  message,
  user,
  users,
  deleteMessage,
  getAuthorName,
}) => {
  const {value, createdAt, authorId, id} = message;
  const t = useTranslation();

  const [contextMenu, toggleContextMenu] = useReducer(state => !state, false);
  const [anchorElement, setAnchorElement] = useState<HTMLDivElement | null>(
    null,
  );
  const [anchorPosition, setAnchorPosition] = useState<AnchorPositionType>({
    top: 0,
    left: 0,
  });

  const {showSnackBar} = useSnackBar();

  const direction = useMemo(() => {
    if (authorId === +user.id) {
      return DirectionEnum.OUT;
    }

    return DirectionEnum.IN;
  }, [authorId, user]);

  const canDeleteMessage = useMemo(() => {
    return (
      user.permissions.has(Permissions.DeleteAnyChatMessage) ||
      +user.id === authorId
    );
  }, [authorId, user.id, user.permissions]);

  const handleContextMenu = useCallback((e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    const left = e.pageX + ADDITIONAL_LEFT_SPACE;
    const top = e.pageY;

    setAnchorPosition({top, left});
    setAnchorElement(null);
    toggleContextMenu();
  }, []);

  const handleClose = () => {
    setAnchorElement(null);
    toggleContextMenu();
  };

  const handleCopyMessage = useCallback(() => {
    navigator.clipboard
      .writeText(message.value)
      .then(() => {
        showSnackBar(t.message.chatMessageCopied, {variant: 'success'});
      })
      .catch(err => {
        showSnackBar(t.errors.chatMessageWasNotCopied, {
          variant: 'error',
        });
        console.error('Failed to copy message: ', err);
      })
      .finally(() => {
        toggleContextMenu();
      });
  }, [message.value, showSnackBar]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <MessageBox direction={direction} onContextMenu={handleContextMenu}>
      <MessageRoot rootProps={{direction}}>
        <h6>{getAuthorName(users, message)}</h6>
        <MessageText>{value}</MessageText>
        <MessageCreatedAt createdAt={createdAt} />
      </MessageRoot>
      <Popover
        open={contextMenu}
        anchorEl={anchorElement}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu ? anchorPosition : undefined}
      >
        <ActionBtnsContainer>
          {canDeleteMessage && (
            <DeleteButton
              variant="contained"
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                deleteMessage(id!, authorId!);
                toggleContextMenu();
              }}
              startIcon={<DeleteIcon />}
            >
              <Typography variant="subtitle2" sx={{width: '100%'}}>
                {t.chat.deleteMessage}
              </Typography>
            </DeleteButton>
          )}
          <CopyMsgButton
            variant="contained"
            onClick={handleCopyMessage}
            startIcon={<ContentCopyIcon />}
          >
            <Typography variant="subtitle2" sx={{width: '100%'}}>
              {t.action.copy}
            </Typography>
          </CopyMsgButton>
        </ActionBtnsContainer>
      </Popover>
    </MessageBox>
  );
};
