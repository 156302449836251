import {Theme} from '@mui/material';

export const colorCondition = (ms: number, theme: Theme) => {
  if (ms > 600000) {
    return theme.palette.error.main;
  } else if (ms > 300000) {
    return theme.palette.warning.main;
  }

  return '';
};
