import {DraggableContext} from 'contexts/DraggableContext';

import {useDraggable} from './DraggableProvider.hooks';

const DraggableProvider: React.FC = ({children}) => {
  const {position, setDraggablePosition, showPin, setToDefault} =
    useDraggable();

  return (
    <DraggableContext.Provider
      value={{
        position,
        showPin,
        setToDefault,
        setPosition: setDraggablePosition,
      }}
    >
      {children}
    </DraggableContext.Provider>
  );
};

export default DraggableProvider;
