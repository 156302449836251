import Chip from '@mui/material/Chip';
import {format} from 'date-fns';
import {FC, useMemo} from 'react';

import {TimeCreatedAt} from './MessageCreatedAt.styled';
import {MessageCreatedAtProps} from './MessageCreatedAt.types';

export const MessageCreatedAt: FC<MessageCreatedAtProps> = ({createdAt}) => {
  const timeCreatedAt = useMemo(() => {
    return createdAt && format(new Date(createdAt), 'HH:mm');
  }, [createdAt]);

  return (
    <>
      <Chip
        sx={{height: '20px'}}
        label={<TimeCreatedAt>{timeCreatedAt}</TimeCreatedAt>}
      />
    </>
  );
};
