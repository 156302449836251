import React, {useEffect, useRef} from 'react';

type AudioStreamProps = {
  stream: MediaStream | null;
};

const AudioStream: React.FC<AudioStreamProps> = ({stream}) => {
  const audioEl = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    if (audioEl.current && stream) {
      audioEl.current.srcObject = stream;
      audioEl.current.play();
    }
  }, [stream]);

  return <audio ref={audioEl} />;
};

export default AudioStream;
