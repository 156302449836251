import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import UserContext from 'contexts/UserContext';
import useSip from 'hooks/useSip';
import {useTranslation} from 'locales/useTranslation';
import PopupState, {bindMenu, bindTrigger} from 'material-ui-popup-state';
import React, {useCallback, useContext} from 'react';
import {Link} from 'react-router-dom';

const LogoutButton: React.FC = () => {
  const [user, {logout}] = useContext(UserContext);
  const [{isInCall}, {disconnectUser}] = useSip();
  const t = useTranslation();

  const handleLogout = useCallback(() => {
    disconnectUser();

    return logout();
  }, [logout, disconnectUser]);

  return (
    <PopupState variant="popover" popupId="account-menu">
      {popupState => (
        <>
          <Button
            color="inherit"
            startIcon={<AccountCircleIcon />}
            {...bindTrigger(popupState)}
            size="large"
          >
            {user && user.username}
          </Button>

          <Menu {...bindMenu(popupState)}>
            <MenuItem component={Link} to="/profile" onClick={popupState.close}>
              {t.title.profile}
            </MenuItem>

            <MenuItem
              disabled={isInCall}
              onClick={() =>
                (handleLogout() as Promise<void>).then(popupState.close)
              }
            >
              {t.action.logout}
            </MenuItem>
          </Menu>
        </>
      )}
    </PopupState>
  );
};

export default LogoutButton;
