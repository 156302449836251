import {CSSObject, IconButton, styled, Theme} from '@mui/material';
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';

import {DRAWER_WIDTH, MOBILE_SCREEN_SIZE} from '../configs';

interface AppBarPros extends MuiAppBarProps {
  open?: boolean;
}

interface MainProps {
  fixed: boolean;
}

const openMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closeMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 15px)`,
});

const DrawerHeader = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarPros>(({theme, open}) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  ...(open && {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: prop => prop !== 'open'})(
  ({theme, open}) => ({
    width: DRAWER_WIDTH,
    flexShrink: 0,
    boxSizing: 'border-box',

    ...(open && {
      ...openMixin(theme),
      '& .MuiDrawer-paper': openMixin(theme),
    }),

    ...(!open && {
      ...closeMixin(theme),
      '& .MuiDrawer-paper': closeMixin(theme),
    }),
  }),
);

const Main = styled('main')<MainProps>(({theme, fixed}) => ({
  flexGrow: 1,
  marginLeft: `calc(${theme.spacing(7)} + 15px)`,
  padding: theme.spacing(2),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',

  [theme.breakpoints.down(MOBILE_SCREEN_SIZE)]: {
    marginLeft: 0,
  },

  ...(fixed && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: DRAWER_WIDTH,

    [theme.breakpoints.down(MOBILE_SCREEN_SIZE)]: {
      marginLeft: 0,
    },
  }),
}));

const Root = styled('div')({
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  position: 'relative',

  '& ::-webkit-scrollbar': {
    width: '7px',
    height: '8px',
  },

  '& ::-webkit-scrollbar-track': {
    background: '#fafafa',
  },

  '& ::-webkit-scrollbar-thumb': {
    background: '#A8A8A8',
    borderRadius: '15px',
  },

  '& ::-webkit-scrollbar-thumb:hover': {
    background: '#888',
  },
});

const DesktopMenu = styled('div')(({theme}) => ({
  [theme.breakpoints.down(MOBILE_SCREEN_SIZE)]: {
    display: 'none',
  },
}));

const MobileMenuIcon = styled(IconButton)(({theme}) => ({
  display: 'none',

  [theme.breakpoints.down(MOBILE_SCREEN_SIZE)]: {
    display: 'block',
    marginRight: theme.spacing(2),
  },
}));

const MobileDrawer = styled('div')(({theme}) => ({
  display: 'none',

  [theme.breakpoints.down(MOBILE_SCREEN_SIZE)]: {
    display: 'block',
  },
}));

const ProgressCont = styled('div')({
  display: 'flex',
  minHeight: '90vh',
  alignItems: 'center',
  justifyContent: 'center',
});

const ChatsContainer = styled('div')<MainProps>(({theme, fixed}) => ({
  position: 'fixed',
  left: `calc(${theme.spacing(9)} + 20px)`,
  bottom: 40,
  zIndex: 100,

  transition: theme.transitions.create('left', {
    easing: theme.transitions.easing.sharp,
    duration: 50,
  }),

  [theme.breakpoints.down(MOBILE_SCREEN_SIZE)]: {
    left: 30,
  },

  ...(fixed && {
    left: DRAWER_WIDTH + 20,
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  }),
}));

export {
  AppBar,
  ChatsContainer,
  DesktopMenu,
  Drawer,
  DrawerHeader,
  Main,
  MobileDrawer,
  MobileMenuIcon,
  ProgressCont,
  Root,
};
