import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'locales/useTranslation';
import {FC} from 'react';

import {Root, Wrapper} from './EmptyChatList.styled';

export const EmptyChatList: FC = () => {
  const t = useTranslation();

  return (
    <Root>
      <Wrapper>
        <MailOutlinedIcon fontSize="large" sx={{height: 50, width: 50}} />
        <Typography variant="h6" align={'center'} sx={{fontWeight: 'bold'}}>
          {t.message.emptyChatListTitle}
        </Typography>
        <Typography variant="subtitle2" color="grey" align={'center'}>
          {t.message.emptyChatListMessage}
        </Typography>
      </Wrapper>
    </Root>
  );
};
