import {createContext} from 'react';
import {DraggableEventHandler} from 'react-draggable';

import {noop} from './../utils/noop';

interface DraggableContextValue {
  position: {x: number; y: number};
  setPosition: DraggableEventHandler;
  setToDefault: () => void;
  showPin: boolean;
}

export const DEFAULT_POSITION = {x: 0, y: 2};

export const DraggableContext = createContext<DraggableContextValue>({
  position: DEFAULT_POSITION,
  setPosition: noop,
  showPin: false,
  setToDefault: noop,
});
