import {ExternalPhoneNumberDto, ExternalPhoneNumberListDto} from '@horn1/api';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import {useTranslation} from 'locales/useTranslation';
import React, {FC, useState} from 'react';
import useSWR from 'swr';
import fetcher from 'utils/fetcher';

import {InputLabel, Select} from '../styled';

export const ExternalNumbersSelect: FC = () => {
  const t = useTranslation();

  const {data: externalPhoneNumbers} = useSWR<ExternalPhoneNumberListDto>(
    '/api/v1/handbooks/external-phone-numbers',
    fetcher,
  );

  const [externalPhoneNumber, setExternalPhoneNumber] =
    useState<ExternalPhoneNumberDto | null>(null);

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="external-phone-numbers">
          {t.menu.handbooks.externalPhoneNumbers}
        </InputLabel>
        <Select
          id="external-phone-numbers"
          value={externalPhoneNumber?.phoneNumber}
          fullWidth
          labelId="external-phone-numbers"
          label={t.menu.handbooks.externalPhoneNumbers}
        >
          {externalPhoneNumbers &&
            externalPhoneNumbers.items.map(item => (
              <MenuItem
                key={item.id}
                value={item.phoneNumber}
                onClick={() => {
                  setExternalPhoneNumber(item);
                }}
              >
                {`${item.name} ${item.phoneNumber}`}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );
};
